import React from "react";

import SingleSDGIcon from "./singlesdgicon";
import SDGS from "../../data/sdgs";

const SDGIcons = () => {
  const goals = [];

  for (let i = 1; i <= 17; i++) {
    goals.push("goal" + i);
  }

  return (
    <>
      {goals.map((goal, i) => (
        <button key={i}>
          <SingleSDGIcon
            goal={goal}
            data={
              SDGS.filter(
                obj =>
                  obj.name ===
                  goal
              )[0]
            }
            i={i}
          />
        </button>
      ))}
    </>
  );
};


export default SDGIcons;
