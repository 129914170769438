import React, { useEffect, useState } from "react";

import styles from "./relevancyvote.module.css";
import RelevantCard from "../components/menti/relevantcard";
import { useParams } from "react-router";
import { Button, Col, Row } from "react-bootstrap";
import { getRelevancyVotesForUserAndStrategy, submitRelevancyVotes } from "../data/strategies";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { validateSession } from "../data/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const RelevancyVote = ({ user, ...props }) => {
	let { id } = useParams();
	const [submitPushed, setSubmitPushed] = useState(false);
	const [cookies] = useCookies(["csrftoken"]);
	const history = useHistory();

	const goalsArr = [];
	for (let i = 1; i <= 17; i++) {
		goalsArr.push({ name: "goal" + i, relevant: false });
	}

	const [goals, setGoals] = useState(goalsArr);

	useEffect(() => {
		const fcn = async () => {
			if (!(await validateSession())) {
				history.push("/session-expired");
				return;
			}
			let votes = await getRelevancyVotesForUserAndStrategy(user.pk, id);
			let newGoals = goals.slice();

			votes.forEach((vote) => {
				let thisGoal = newGoals.filter((x) => {
					return x.name === `goal${vote.sdg}`;
				})[0];
				thisGoal.relevant = vote.is_relevant;
			});
			setGoals(newGoals);
		};
		fcn();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const toggleRelevant = async (goal) => {
		const index = goals.map((goal) => goal.name).indexOf(goal);
		const copyGoals = goals.slice();
		copyGoals[index] = { name: goal, relevant: !copyGoals[index].relevant };
		setGoals(copyGoals);
	};

	const handleSubmit = async (e) => {
		setSubmitPushed(true);
		if (e) {
			e.preventDefault();
		}
		if (id) {
			await submitRelevancyVotes(id, goals, cookies.csrftoken);
			history.push(`/`);
		} else {
			console.error("No workshopid found. Cannot assess.");
		}
	};

	return (
		<main className={"m-4"}>
			<Row className={"d-flex justify-content-around pb-4"}>
				<Col className={"col-md-4 mt-2 pt-4"} style={{ border: "1px solid #dadada" }}>
					<form className={styles.form} onSubmit={(e) => handleSubmit(e)}>
						{goals.map((goal, i) => {
							return (
								<RelevantCard
									key={i}
									goal={goal.name}
									value={goal.relevant}
									handleChange={(name) => toggleRelevant(name)}
								/>
							);
						})}
						<Button className={"mt-2 mb-2"} variant={"success"} size={"lg"} type="submit" disabled={submitPushed}>
							{submitPushed ? <FontAwesomeIcon icon={faSpinner} spin /> : "Submit"}
						</Button>
					</form>
				</Col>
			</Row>
		</main>
	);
};

export default RelevancyVote;
