import './Dashboard.css';

import { useEffect, useState } from "react";
import {Breadcrumb, BreadcrumbItem, Card, Col, Container, Form, Row} from 'react-bootstrap';
import ContextAndStrategyForm from '../components/ContextAndStrategyForm';
import DirectEffectsPlot from "../components/DirectEffectsPlot";
import Alpha from "../components/visuals/alpha";
import Beta from "../components/visuals/beta";

import Delta from "../components/visuals/delta";
import IndirectEffectsPlot from "../components/IndirectEffectsPlot";
import { useHistory, useParams } from "react-router";
import { getContext } from '../data/contexts';
import { getStrategy, getVotesForStrategyAndIteration } from "../data/strategies";
import { calculateStrategyAverageFromVotes } from "../data/calculate";
import { validateSession } from "../data/auth";
import {Link} from "react-router-dom";


function Dashboard() {
  let { strategyId, scoringIteration } = useParams();

  const [contextId, setContextId] = useState("1");
  const [contextMap, setContextMap] = useState([])
  const [strategy, setStrategy] = useState([])
  const [strategyArray, setStrategyArray] = useState([])
  const [strategyLoaded, setStrategyLoaded] = useState(false)

  const [showDirectEffects, setShowDirectEffects] = useState(false);
  const [showContext, setShowContext] = useState(false);
  const [showRowAndColSums, setShowRowAndColSums] = useState(false);
  const [showIndirectEffects, setShowIndirectEffects] = useState(false);

  const history = useHistory();

  async function loadNewData(newContextId) {
    if (newContextId === undefined) {
      newContextId = contextId;
    }
    let contextObject = await getContext(newContextId);
    setContextMap(contextObject.matrix);
  }

  function toggleSwitch(event) {
    switch (event.target.id) {
      case "direct-effects-switch":
        setShowDirectEffects(!showDirectEffects);
        break;
      case "context-switch":
        setShowContext(!showContext);
        break;
      case "sums-switch":
        setShowRowAndColSums(!showRowAndColSums);
        break;
      case "indirect-effects-switch":
        setShowIndirectEffects(!showIndirectEffects);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    async function prepare() {
      if (!(await validateSession())) {
        history.push('/session-expired')
        return;
      }
      let strategy = await getStrategy(strategyId);
      setStrategy(strategy)
      let votes = await getVotesForStrategyAndIteration(strategyId, scoringIteration);
      let thisStrategy = calculateStrategyAverageFromVotes(votes);
      setStrategyArray(thisStrategy);
    }

    if (!strategyLoaded) {
      setStrategyLoaded(true);
      prepare();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container fluid className={"pt-4"}>
      <h2>Cross-Impact Context Matrix </h2>
      <Row>
        <Col>
          <Breadcrumb>
            <BreadcrumbItem linkAs={Link} linkProps={{to: '/'}}>
              Home
            </BreadcrumbItem>
            <BreadcrumbItem active={true}>
              Dashboard for "{strategy.name}"
            </BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row className={"pb-1 pt-1"}>
        <Col md={2} xl={2}className={"d-flex align-items-end justify-content-center"}>
          <h3 className={"text-center"}>Direct Effects</h3>
        </Col>
        <Col md={5} xl={3} className={""}>
          <ContextAndStrategyForm contextId={contextId} setContextId={setContextId} loadNewData={loadNewData} />
        </Col>
        <Col md={5} xl={6} >
          <Form className={"h-100 d-flex flex-row justify-content-between align-items-end pb-2"}>
            <Form.Check
              type="switch"
              id="direct-effects-switch"
              label="Direct Effects"
              onChange={toggleSwitch}
              checked={showDirectEffects}
            />
            <Form.Check
              type="switch"
              id="context-switch"
              label="Context Matrix"
              onChange={toggleSwitch}
              checked={showContext}
            />
            <Form.Check
              type="switch"
              id="sums-switch"
              label="Row & Column Sums"
              onChange={toggleSwitch}
              checked={showRowAndColSums}
            />
            <Form.Check
              type="switch"
              id="indirect-effects-switch"
              label="Indirect Effects"
              onChange={toggleSwitch}
              checked={showIndirectEffects}
            />
          </Form>
        </Col>
      </Row>
      <Row>
        <Card className={"h-100 w-100"}>
          <Card.Body className={"text-center d-flex col-12"}>
            <Col md={2} className={"col-3 context-pane"}>
              {/* <h2 style={{ position: "fixed", top: "8rem" }}>Direct Effects</h2> */}
              <div className={"d-flex flex-column flex-grow-1"} style={{visibility: showDirectEffects ? "visible": "hidden"}}>
                <DirectEffectsPlot strategy={strategyArray} iteration={parseInt(scoringIteration)} showIcons={false} />
              </div>
            </Col>
            <Col md={10} className={"col-6 context-pane"}>
              <div className={"h-100 w-100 d-flex flex-column justify-content-start align-items-start"}>
                <Row>
                  <Col xs={10} className={"d-flex flex-column align-items-end p-0"} style={{visibility: showContext ? "visible": "hidden"}}>
                    {contextMap.length > 0 && (<Alpha contextMatrix={contextMap} />)}
                  </Col>
                  <Col xs={2} className={"d-flex flex-column align-items-start p-0"} style={{visibility: showRowAndColSums ? "visible": "hidden"}}>
                    {contextMap.length > 0 && (<Delta contextMatrix={contextMap} />)}
                  </Col>
                </Row>
                <Row style={{ paddingTop: "30px" }}>
                  <Col xs={10} className={"d-flex flex-column align-items-end"} style={{visibility: showRowAndColSums ? "visible": "hidden"}}>
                    {contextMap.length > 0 && (<Beta contextMatrix={contextMap} />)}
                  </Col>
                  <Col xs={2} />
                </Row>
              </div>
            </Col>
          </Card.Body>
        </Card>
      </Row>
      <Row>
        <Card className={"h-100 w-100"}>
          <Card.Body className={"text-center d-flex flex-direction-row"}>
            <Col md={2}>
              <h2 className={"mb-0"}>Indirect effects</h2>
              <p className={"text-muted"}>Relative scale</p>
            </Col>
            <Col md={10} className={"indirect-wrapper"} style={{visibility: showIndirectEffects ? "visible": "hidden"}}>
              <IndirectEffectsPlot contextMap={contextMap} strategy={strategyArray}
                iteration={parseInt(scoringIteration)} />
            </Col>
          </Card.Body>
        </Card>
      </Row>
    </Container>
  );
}

export default Dashboard;
