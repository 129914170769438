import React from 'react';

import { ResponsiveScatterPlot } from '@nivo/scatterplot';
import { getVoteColor } from '../utils/resultsFunctions';

function ResultsPlot({ data, ticks }) {

        const filteredData = data.map(x => {
                return {
                        data: x.data.filter(y => y.z !== 0),
                        id: x.id
                }
        })
        // const filteredData = data.slice(0);
        console.log({ filteredData, data })

        return (
                <>
                        <ResponsiveScatterPlot
                                data={filteredData}
                                blendMode={'normal'}
                                margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
                                xFormat={function (e) { return "sdg" + e }}
                                axisBottom={{
                                        tickValues: ticks,
                                        orient: 'bottom',
                                        tickSize: 12,
                                        tickPadding: 6,
                                        tickRotation: 0,
                                        legend: 'SDG',
                                        legendPosition: 'middle',
                                        legendOffset: 60
                                }}
                                axisLeft={{tickValues: [-3, -2, -1, 0, 1, 2, 3]}}
                                gridXValues={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]}
                                gridYValues={[-3, -2, -1, 0, 1, 2, 3]}
                                yScale={{ type: 'linear', min: -3, max: 3, interval: 1 }}
                                colorBy="id"
                                colors={x => getVoteColor(x.serieId)}
                                nodeSize={{ key: 'data.z', values: [0, 4], sizes: [0, 32] }}
                                tooltip={({ node }) => (
                                        <div
                                                style={{
                                                        color: "#fff",
                                                        background: '#333',
                                                        padding: '12px 16px',
                                                }}
                                        >
                                                <strong>
                                                        {`${node.formattedX.toUpperCase()}`}
                                                </strong>
                                                <br />
                                                {`Score: ${node.formattedY}`}
                                                <br />
                                                Scores: {node.data.z}
                                        </div>
                                )
                                }
                        />
                </>
        )
}

export default ResultsPlot;