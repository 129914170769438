import React from "react";
import {Redirect, Route} from "react-router-dom";

function ProtectedRoute({component: Component, user, ...restOfProps}) {
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        Object.keys(user).length > 0 ? <Component user={user} {...props} /> : <Redirect to="/login"/>
      }
    />
  );
}

export default ProtectedRoute;