
export const getAllContexts = async () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
    credentials: 'include',
  };

  let response = await fetch("/api/contexts/", requestOptions)
  if (!response.ok) {
    throw new Error('Failure');
  }
  return await response.json();
}

export const getContext = async (contextId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
    credentials: 'include',
  };

  let response = await fetch(`/api/contexts/${contextId}/`, requestOptions)
  if (!response.ok) {
    throw new Error('Failure');
  }
  return await response.json();
}