export const calculateEffect = (c, s) => {
  // Iterating through matrix columns
  let effect = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  for (let i = 0; i < c.length; i++) {
    // Iterating through cells in column. Also strategy index.
    for (let j = 0; j < c[i].length; j++) {
      effect[i] += c[j][i] * s[j];
    }
  }
  return effect;
}

export const calculateEffectMatrix = (c, s) => {
  // Iterating through matrix columns
  let effect = [];
  for (let row = 0; row < c.length; row++) {
    effect[row] = c[row].slice();
  }

  for (let i = 0; i < c.length; i++) {
    // Iterating through cells in column. Also strategy index.
    for (let j = 0; j < c[i].length; j++) {
      if (i === j) {
        effect[j][i] = 0;
      }
      effect[j][i] = c[j][i] * s[j];
    }
  }
  return effect;
}

export const calculateStrategyAverageFromVotes = (votes) => {
  let thisStrategy = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let nVotes = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  thisStrategy.forEach((v, idx) => {
    votes
      .filter((vote) => {
        return vote.sdg === (idx + 1)
      })
      .forEach((vote) => {
        const value = vote.lower_range < 0 ? vote.lower_range : vote.upper_range;
        thisStrategy[idx] += value;
        nVotes[idx] += 1;
      })
    thisStrategy[idx] = thisStrategy[idx] / nVotes[idx];
  })
  return thisStrategy;
}