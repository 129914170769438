import { calculateEffect } from "../data/calculate";
import Beta from './visuals/beta';

const IndirectEffectsPlot = ({ contextMap, strategy, iteration }) => {
  const data_1 = strategy.length > 0 ? strategy : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const indirectEffect1 = calculateEffect(contextMap, data_1);

  return (
    <Beta contextMatrix={[]} calculated={indirectEffect1} />
  )
}

export default IndirectEffectsPlot;