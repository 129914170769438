import React, { useEffect, useState } from "react";

import wheelstyle from "./wheelresults.module.css";

import { wheelResultsFixing } from "../components/utils/resultsFunctions";
import { useParams } from "react-router";
import { getStrategy, getVotesForStrategyAndIteration } from "../data/strategies";
import { Button, Col, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import useInterval from "../hooks/useInterval";
import { calculateStrategyAverageFromVotes } from "../data/calculate";
import { validateSession } from "../data/auth";
import MentiResults from "../components/MentiResults";

const ScoringResults = ({ user }) => {
  let { strategyId, scoringIteration } = useParams();
  const history = useHistory();
  const iteration = parseInt(scoringIteration);

  const [voteData, setVoteData] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [scatterData, setScatterData] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [filteredData, setFilteredData] = useState(false);
  const [avgData, setAvgData] = useState(false);
  const [voteAmount, setVoteAmount] = useState(0);
  const [title, setTitle] = useState("No title yet");
  const [loading, setLoading] = useState("true");
  const [hasRequestedData, setHasRequestedData] = useState("false");

  // eslint-disable-next-line
  const [strategy, setStrategy] = useState({})
  // eslint-disable-next-line
  const [strategyArray, setStrategyArray] = useState([])
  // eslint-disable-next-line
  const [included, setIncluded] = useState([]);

  const updateVotes = async () => {
    let currentVotes = await getVotesForStrategyAndIteration(strategyId, iteration);
    let thisStrategy = calculateStrategyAverageFromVotes(currentVotes);
    setStrategyArray(thisStrategy);
    // First, get the inclusion decisions.
    let strategy = await getStrategy(strategyId);
    let includedArr = [];
    strategy.relevancy_decisions.forEach((d, idx) => {
      if (d) {
        includedArr.push((idx + 1).toString());
      }
    })
    wheelResultsFixing(
      currentVotes,
      setVoteData,
      setScatterData,
      setFilteredData,
      setVoteAmount,
      setAvgData,
      includedArr
    );

    return strategy;
  }

  useEffect(() => {
    const fcn = async () => {
      if (!(await validateSession())) {
        history.push('/session-expired')
        return;
      }
      // First, get the inclusion decisions.
      let strategy = await getStrategy(strategyId);
      let includedArr = [];
      strategy.relevancy_decisions.forEach((d, idx) => {
        if (d) {
          includedArr.push((idx + 1).toString());
        }
      })
      setIncluded(includedArr);

      setStrategy(strategy);
      setTitle(strategy.name);
      await updateVotes();
      setLoading(false);
    }
    if (hasRequestedData) {
      setHasRequestedData(true);
      fcn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(async () => {
    await updateVotes()
  }, 5000)

  return (
    <Row className={"h-100 mt-2 ml-2 mr-2 d-flex justify-content-between"}>
      <Col md={12} className={"d-flex flex-row justify-content-between"}>
        <div className={wheelstyle.info}>
          {title && <h1 className={"mb-1"}>Results for Session {iteration}</h1>}
          {title && <h3>Strategy: {title}</h3>}
          {voteAmount > 0 && <p className={"m-0 mb-2"}>Amount of people who assessed: {voteAmount}</p>}
        </div>

      </Col>
      {loading ? (
        <Col sm={12} className={""}>
          <h3 className={"text-center"}>Loading...</h3>
        </Col>
      ) : (
        <Col sm={12} className={""}>
          <MentiResults title={title} voteData={voteData} scatterData={scatterData} filteredData={filteredData} avgData={avgData} voteAmount={voteAmount} />
        </Col>
      )}
      <Col sm={12} className={"pr-4 pb-4 d-flex justify-content-end"}>
        <Button variant={"primary"} size={"lg"} onClick={() => {
          history.push(`/`)
        }}>Continue</Button>
      </Col>
    </Row>
  );
};

export default ScoringResults;
