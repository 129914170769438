function getRanges(item) {
  let lower_index = false;
  let upper_index = false;
  if (item.lower_range === -3) {
    lower_index = 0;
  } else if (item.lower_range === -2) {
    lower_index = 1;
  } else if (item.lower_range === -1) {
    lower_index = 2;
  }

  if (item.upper_range === "0" && item.lower_range === "0") {
    upper_index = 3;
  } else if (item.upper_range === 1) {
    upper_index = 4;
  } else if (item.upper_range === 2) {
    upper_index = 5;
  } else if (item.upper_range === 3) {
    upper_index = 6;
  }

  return [lower_index, upper_index];
}

function createFinalData(item, found, final, sums) {
  if (found.indexOf(item.sdg) === -1) {
    found.push(item.sdg);
    final[item.sdg] = Object.assign({}, item);
    sums[item.sdg] = Object.assign({}, item);
    sums[item.sdg].len = 1;
    final[item.sdg].lower_rangeColor = "#E8213E";
    sums[item.sdg].lower_rangeColor = "#E8213E";
    final[item.sdg].upper_rangeColor = "#ccf33c";
    sums[item.sdg].upper_rangeColor = "#ccf33c";
  } else {
    const lowerFound = final[item.sdg].lower_range;
    const upperFound = final[item.sdg].upper_range;
    final[item.sdg].lower_range = item.lower_range < lowerFound ? item.lower_range : lowerFound;
    final[item.sdg].upper_range = item.upper_range > upperFound ? item.upper_range : upperFound;
    sums[item.sdg].lower_range = sums[item.sdg].lower_range + item.lower_range;
    sums[item.sdg].upper_range = sums[item.sdg].upper_range + item.upper_range;
    sums[item.sdg].len += 1;
  }
}

function countVotes(sdg, i, sortedData, votes) {
  sortedData
    .filter((x) => x.sdg === parseInt(sdg.title.substr(3)))
    .forEach((item) => {
      if (item.lower_range === 0 && item.upper_range !== 0) {
        votes[i][item.upper_range] += 1;
      } else if (item.upper_range === 0 && item.lower_range !== 0) {
        votes[i][item.lower_range] += 1;
      } else if (item.upper_range === 0 && item.lower_range === 0) {
        votes[i][item.lower_range] += 1;
      } else {
        votes[i][item.lower_range] += 1;
        votes[i][item.upper_range] += 1;
      }
    });
}

const getSDGColor = (n) => {
  switch (n) {
    case 1:
      return "#EB1C2E";
    case 2:
      return "#D3A029";
    case 3:
      return "#279B48";
    case 4:
      return "#C31F33";
    case 5:
      return "#EF402B";
    case 6:
      return "#00AED9";
    case 7:
      return "#FDB713";
    case 8:
      return "#8F1837";
    case 9:
      return "#F36D25";
    case 10:
      return "#E11484";
    case 11:
      return "#F99D25";
    case 12:
      return "#CF8D2A";
    case 13:
      return "#48773E";
    case 14:
      return "#007DBC";
    case 15:
      return "#3EB048";
    case 16:
      return "#02558B";
    case 17:
      return "#183668";
    default:
      return "#C31F33";
  }
};

const getStratColor = (index) => {
  switch (index) {
    case 0:
      return "#2bd4ff";
    case 1:
      return "#ffb90a";
    case 2:
      return "#8c0aff";
    case 3:
      return "#0affa9";
    case 4:
      return "#efef00";
    case 5:
      return "#00ef03";
    default:
      return "#0affa9";
  }
};

const getVoteColor = (vote) => {
  switch (vote > 0 ? Math.ceil(vote) : Math.floor(vote)) {
    case -3:
      return "#c00000";
    case -2:
      return "#e37548";
    case -1:
      return "#f5c07a";
    case 0:
      return "#e7e696";
    case 1:
      return "#c1df86";
    case 2:
      return "#69ae65";
    case 3:
      return "#385724";
    default:
      return "#e2c72b";
  }
};

const getVoteColorBlind = (vote) => {
  switch (Math.round(vote)) {
    case -3:
      return "#F8696B";
    case -2:
      return "#F8696B";
    case -1:
      return "#F8696B";
    case 0:
      return "#ffffff";
    case 1:
      return "#5A8AC6";
    case 2:
      return "#5A8AC6";
    case 3:
      return "#5A8AC6";
    default:
      return "#e2c72b";
  }
};


const getIndirectColor = (vote) => {
  /* Vote is in [-51, 51] */
  if (vote < 0) {
    return "#f8c6c7";
  } else {
    return "#bfd5f0";
  }
};

const wheelResultsFixing = (
  res,
  setVoteData,
  setScatterData,
  setFilteredData,
  setVoteAmount,
  setAvgData,
  included,
  log
) => {
  const sortedData = res.sort((a, b) => a.sdg - b.sdg);

  const votes = [];

  for (let i = 1; i <= 17; i++) {
    votes.push({
      title: "sdg" + i,
      "-3": 0,
      "-2": 0,
      "-1": 0,
      "0": 0,
      "1": 0,
      "2": 0,
      "3": 0,
      "-3Color": "#801535",
      "-2Color": "#A31C45",
      "-1Color": "#E8213E",
      "0Color": "#fff200",
      "1Color": "#ccf33c",
      "2Color": "#47ed09",
      "3Color": "#5DBB46",
    });
  }

  const found = [];
  const final = {};
  const filtered = [];
  const sums = {};
  const avgArr = [];
  let voteLens = [];

  if (log) console.log({ sortedData });

  sortedData.map((item) => createFinalData(item, found, final, sums));

  if (log) console.log({ votes });

  votes.map((sdg, i) => countVotes(sdg, i, sortedData, votes));

  if (setVoteData) setVoteData(votes);

  if (log) console.log({ votes });

  const scatter = [
    { id: "-3", data: [] },
    { id: "-2", data: [] },
    { id: "-1", data: [] },
    { id: "0", data: [] },
    { id: "1", data: [] },
    { id: "2", data: [] },
    { id: "3", data: [] },
  ];

  for (let n = 0; n < scatter.length; n++) {
    for (let x = 1; x <= 17; x++) {
      scatter[n].data.push({
        id: x,
        x: x,
        y: parseInt(scatter[n].id),
        z: 0,
      });
    }
  }

  sortedData.forEach((item, i) => {
    const [lower_index, upper_index] = getRanges(item);

    if (lower_index !== false) {
      scatter[lower_index].data[item.sdg - 1]["z"] += 1;
    }
    if (upper_index !== false) {
      scatter[upper_index].data[item.sdg - 1]["z"] += 1;
    }
    if (upper_index === false && lower_index === false)
    {
      scatter[3].data[item.sdg - 1]["z"] += 1;
    }
  });
  if (setScatterData) setScatterData(scatter);
  if (log) console.log({ scatter });

  // eslint-disable-next-line no-unused-vars
  for (let [key, val] of Object.entries(final)) {
    filtered.push(val);
  }

  filtered.map(sdg => sdg.title = "sdg" + sdg.sdg);

  if (setFilteredData) setFilteredData(filtered);

  // eslint-disable-next-line no-unused-vars
  for (let [voteKey, vote] of Object.entries(sums)) {
    avgArr.push({
      lower_range: (vote.lower_range / vote.len).toFixed(3),
      lower_rangeColor: vote.lower_rangeColor,
      upper_range: (vote.upper_range / vote.len).toFixed(3),
      upper_rangeColor: vote.upper_rangeColor,
      title: `sdg${vote.sdg}`,
      avg: ((vote.lower_range + vote.upper_range) / vote.len).toFixed(1),
      len: vote.len,
    });
    voteLens.push(vote.len);
  }

  if (voteLens.length > 0) {
    if (setVoteAmount) setVoteAmount(Math.max(...voteLens));
  } else {
    if (setVoteAmount) setVoteAmount(0);
  }
  if (setAvgData) setAvgData(avgArr);
  if (log) console.log({ avgArr });
};

export {
  getRanges,
  createFinalData,
  countVotes,
  getSDGColor,
  getStratColor,
  getVoteColor,
  getVoteColorBlind,
  getIndirectColor,
  wheelResultsFixing
};
