import "./BlobMatrix.css";
import React from "react";
import { Card } from "react-bootstrap";
import { valuesToEffectTypeColor } from "./utils/styling";
import { getSDGImage } from "../img/images";

const BlobMatrix = ({ contextMap, strategy, setCurrentlyHoveredSDG, comments, clickOnInteraction, filter, sliderFilterPlusPlus,	sliderFilterMinusMinus,	sliderFilterMinusPlus, sliderFilterPlusMinus }) => {
	const thisStrategy = strategy.length > 0 ? strategy : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

	const handleCircleClick = (i, j, circle) => {
		clickOnInteraction(i + 1, j + 1);
	};

	const checkAllFilters = (index, index2) => {
		if (checkFilter(index, index2) && checkSliderFilter(index, index2)) {
			return true;
		} else {
			return false;
		}
	};

	const checkFilter = (index, index2) => {
		if (contextMap[index][index2] && strategy[index]) {
			const contextSign = contextMap[index][index2].toFixed(1);
			const directEffectSign = strategy[index].toFixed(1);

			if (!filter.length) {
				return true;
			} else if (filter.indexOf("++") !== -1 && contextSign > 0 && directEffectSign > 0) {
				return true;
			} else if (filter.indexOf("--") !== -1 && contextSign < 0 && directEffectSign < 0) {
				return true;
			} else if (filter.indexOf("+-") !== -1 && contextSign < 0 && directEffectSign > 0) {
				return true;
			} else if (filter.indexOf("-+") !== -1 && contextSign > 0 && directEffectSign < 0) {
				return true;
			} else {
				return false;
			}
		}
	};

	const checkSliderFilter = (index, index2) => {
		if (contextMap[index][index2] && strategy[index]) {
			const val = contextMap[index][index2] * strategy[index];

			//console.log(val, sliderFilterNeg, sliderFilterPos)
			if (contextMap[index][index2] > 0)
			{
				if (strategy[index] > 0)
				{
					return val >= sliderFilterPlusPlus;
				}
				else
				{
					return val <= sliderFilterMinusPlus;
				}
			}
			else
			{
				if (strategy[index] > 0)
				{
					return val <= sliderFilterPlusMinus;
				}
				else
				{
					return val >= sliderFilterMinusMinus;
				}
			}
		}
		return false;
	};

	return (
		<Card>
			<Card.Body>
				<div className={"table-responsive"} style={{ height: "fit-content" }}>
					<table className="table blob-matrix table-responsive">
						<tbody>
							{/* First row with empty - images - empty */}
							<tr>
								<td />
								{Array.from({ length: 17 }, (v, k) => k + 1).map((i) => (
									<td key={i}>
										<img className="" src={getSDGImage(i)} alt={"SDG " + i.toString()} />
									</td>
								))}
								<td />
							</tr>
							{/* Render the 1st through 18th row with image-data-image */}
							{contextMap.map((row, index) => {
								return (
									<tr key={index}>
										<td>
											<img
												className=""
												src={getSDGImage(index + 1)}
												alt={"SDG " + (index + 1).toString()}
											/>
										</td>
										{row.map((cell, index2) =>
											checkAllFilters(index, index2) ? (
												<td key={index2}>
													<svg
														viewBox="0 0 140 140"
														preserveAspectRatio="xMinYMin meet"
														style={{ height: "40px" }}
														onClick={handleCircleClick.bind(
															this,
															index,
															index2
														)}
														onMouseEnter={() => {
															setCurrentlyHoveredSDG([index, index2]);
														}}
														onMouseLeave={() => {
															setCurrentlyHoveredSDG([]);
														}}>
														<g>
															<line
																className="blobmatrix-point"
																x1="0%"
																x2="100%"
																y1="50%"
																y2="50%"
															/>
															<line
																className="blobmatrix-point"
																x1="50%"
																x2="50%"
																y1="0%"
																y2="100%"
																fill={"#404040"}
															/>
															<circle
																r={
																	(
																		(Math.abs(
																			cell *
																				thisStrategy[
																					index
																				]
																		) /
																			9) *
																			40 +
																		(Math.abs(
																			thisStrategy[
																				index
																			]
																		) > 0 &&
																		Math.abs(cell) >
																			0
																			? 10
																			: 0)
																	).toFixed() + "%"
																}
																fill={valuesToEffectTypeColor(
																	cell,
																	thisStrategy[index]
																)}
																cx="50%"
																cy="50%"
															/>
															<text
																x="41%"
																y="62%"
																fontSize={"3.5rem"}>
																{comments.filter(
																	(x) =>
																		x.sdg_1 ===
																			index +
																				1 &&
																		x.sdg_2 ===
																			index2 +
																				1
																).length > 0
																	? comments.filter(
																			(x) =>
																				x.sdg_1 ===
																					index +
																						1 &&
																				x.sdg_2 ===
																					index2 +
																						1
																	  ).length
																	: ""}
															</text>
														</g>
													</svg>
												</td>
											) : (
												<td key={index2}>
													<svg
														viewBox="0 0 140 140"
														preserveAspectRatio="xMinYMin meet"
														style={{ height: "40px" }}>
														<g>
															<line
																className="blobmatrix-point"
																x1="0%"
																x2="100%"
																y1="50%"
																y2="50%"
															/>
															<line
																className="blobmatrix-point"
																x1="50%"
																x2="50%"
																y1="0%"
																y2="100%"
																fill={"#404040"}
															/>
														</g>
													</svg>
												</td>
											)
										)}
										<td>
											<img
												className=""
												src={getSDGImage(index + 1)}
												alt={"SDG " + (index + 1).toString()}
											/>
										</td>
									</tr>
								);
							})}
							{/* Last row with empty - images - empty */}
							<tr>
								<td />
								{Array.from({ length: 17 }, (v, k) => k + 1).map((i) => (
									<td key={i}>
										<img className="" src={getSDGImage(i)} alt={"SDG " + i.toString()} />
									</td>
								))}
								<td />
							</tr>
						</tbody>
					</table>
				</div>
			</Card.Body>
		</Card>
	);
};
export default BlobMatrix;
