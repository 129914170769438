import React from "react";

import style from "./resultslegend.module.css";

function ResultsLegend() {
	return (
		<div className={style.legendswrapper}>
			<div className={style.iconwrapper}>
				<div className={`${style.icon} ${style.darkred}`}></div>
				<p>-3</p>
				<p className={style.desc}>Strongly restricting</p>
			</div>
			<div className={style.iconwrapper}>
				<div className={`${style.icon} ${style.mediumred}`}></div>
				<p>-2</p>
				<p className={style.desc}>Moderately restricting</p>
			</div>
			<div className={style.iconwrapper}>
				<div className={`${style.icon} ${style.red}`}></div>
				<p>-1</p>
				<p className={style.desc}>Weakly restricting</p>
			</div>
			<div className={style.iconwrapper}>
				<div className={`${style.icon} ${style.yellow}`}></div>
				<p>0</p>
				<p className={style.desc}>No influence</p>
			</div>
			<div className={style.iconwrapper}>
				<div className={`${style.icon} ${style.green}`}></div>
				<p>1</p>
				<p className={style.desc}>Weakly promoting</p>
			</div>
			<div className={style.iconwrapper}>
				<div className={`${style.icon} ${style.mediumgreen}`}></div>
				<p>2</p>
				<p className={style.desc}>Moderately promoting</p>
			</div>
			<div className={style.iconwrapper}>
				<div className={`${style.icon} ${style.darkgreen}`}></div>
				<p>3</p>
				<p className={style.desc}>Strongly promoting</p>
			</div>
		</div>
	);
}

export default ResultsLegend;
