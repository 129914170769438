import React from "react";

import styles from "./beta.module.css";

const Beta = ({ contextMatrix, calculated }) => {
  const cm = contextMatrix.length > 0 ? contextMatrix : [];
  let data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  if (!calculated) {
    console.log({ cm })
    cm.forEach((row) => {
      row.forEach((value, col_idx) => {
        data[col_idx] += value
      })
    });
  } else {
    data = calculated;
  }
  const hasNegative = data.some(x => x < 0);

  const negatives = hasNegative ? data.map(x => x > 0 ? 0 : x) : data;
  const positives = hasNegative ? data.map(x => x < 0 ? 0 : x) : data;

  const max = Math.max.apply(null, data.map(Math.abs));
  return (
    <>
      {data.length > 0 && (
        <>
          {hasNegative ?
            <>
              <div className={styles.row}>
                {positives.map((barN, index) => {
                  const height = `${(barN / max) * 100}%`;
                  return <div key={index} className={styles.bar} style={{ height }} />;
                })}
              </div>
              <div className={`${styles.row} ${styles.reverse}`}>
                {negatives.map((barN, index) => {
                  const height = `${(Math.abs(barN) / max) * 100}%`;
                  return <div key={index} className={`${styles.bar} ${styles.negative}`} style={{ height }} />;
                })}
              </div>
            </>
            :
            <div className={styles.row}>
              {data.map((barN, index) => {
                const height = `${(barN / max) * 100}%`;
                return <div key={index} className={styles.bar} style={{ height }} />;
              })}
            </div>
          }
        </>
      )}
    </>
  );
};

export default Beta;
