import React from "react";
import {getVoteColor} from "../utils/resultsFunctions";
import SDGIcons from "./sdgicons";

import styles from "./alpha.module.css";

const Alpha = ({contextMatrix}) => {
  const data = contextMatrix.length > 0 ? contextMatrix : [];
  return (
    <div>
      {data.length > 0 && (
        <>
          <div className={styles.coltitles}>
            <SDGIcons/>
          </div>
          <div className={styles.rowmatrix}>
            <div className={styles.rows}>
              <SDGIcons/>
            </div>
            <div className={styles.matrix}>
              {data.map((row, i) => (
                <div key={i} className={styles.row}>
                  {row.map((cell, index) => {
                    const background = getVoteColor(cell);
                    return (
                      <div
                        key={index}
                        className={`${styles.cell}`}
                        style={{background, color: background}}>
                        {cell.toFixed(2)}
                      </div>
                    );
                  })}
                </div>
              ))}
            </div>
            <div className={styles.rows}>
              <SDGIcons/>
            </div>
          </div>
          <div className={styles.coltitles}>
            <SDGIcons/>
          </div>
        </>
      )}
    </div>
  );
};

export default Alpha;
