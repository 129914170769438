export const login = async (email, password, csrftoken) => {
  const myHeaders = new Headers();
  myHeaders.append("X-CSRFToken", csrftoken);
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  const urlencoded = new URLSearchParams();
  urlencoded.append("password", password);
  urlencoded.append("email", email);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow',
    credentials: 'include',
    mode: 'cors',
  };

  const x = await fetch("/rest-auth/login/", requestOptions)
  return x.status
};

export const validateSession = async () => {
  let x = await fetch("/api/session/", {
    credentials: "include",
    redirect: 'follow'
  });
  let y = await x.json();
  return y.isAuthenticated;
};

export const logout = async (csrftoken) => {
  const myHeaders = new Headers();
  myHeaders.append("X-CSRFToken", csrftoken);

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    credentials: 'include',
    mode: 'cors',
  };

  const x = await fetch("/rest-auth/logout/", requestOptions)
  const y = await x.json()
  return y.detail;
}

export const getUser = async () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
    credentials: 'include',
  };

  const x = await fetch("/rest-auth/user/", requestOptions)
  return await x.json()
};

export const getAllUsers = async () => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
    credentials: 'include',
  };

  const x = await fetch("/api/get_all_users/", requestOptions)
  return await x.json()
};