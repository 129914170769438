import React, {useEffect} from "react";
import {useCookies} from "react-cookie";
import {useHistory} from "react-router-dom";
import {logout} from "../data/auth";

function SessionExpired({setUser}) {

  const [cookies] = useCookies(['csrftoken']);
  const history = useHistory();

  useEffect(() => {
    async function performLogout() {
      await logout(cookies.csrftoken);
      setUser({});
      localStorage.removeItem("CompassUser");
      setTimeout(() => { console.log("Session ended"); history.push('/') }, 2000);
    }
    performLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <main className={"p-4 h-100 w-100 text-center"}>
      <h4>Session has expired...</h4>
    </main>
  );
}

export default SessionExpired;
