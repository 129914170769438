import React from "react";
import {getSDGColor, getVoteColor} from "../utils/resultsFunctions";

import styles from "./wheelbars.module.css";

const WheelBars = ({sdgvotes, n, votecolor}) => {
  const rotation = "translate(0px, -393px) rotate(" + (10 + 21 * (n - 1)) + "deg)";
  const background = getSDGColor(n);

  const PositiveBars = [];
  const NegativeBars = [];

  const getAngleAndDist = (voteAmount, index, pos) => {
    const totalVoteAmount = pos ? parseInt(sdgvotes[0], sdgvotes[1], sdgvotes[2], sdgvotes[3]) : parseInt(sdgvotes["-1"], sdgvotes["-2"], sdgvotes["-3"]);
    const angleChange = (360 / 17) / totalVoteAmount;
    const mid = (voteAmount + 1) / 2;
    const angle = (index * angleChange) - angleChange * (voteAmount / 2);
    const botPercantage = 4 - (((Math.abs(mid - (index + 1))) / voteAmount) * 5) + "%";
    return [angle, botPercantage];
  }

  for (let zeroi = 0; zeroi < sdgvotes["0"]; zeroi++) {
    const [angle, botPercantage] = getAngleAndDist(sdgvotes["0"], zeroi);
    PositiveBars.push(
      <div
        className={styles.bar}
        style={{
          transform: "rotate(" + angle + "deg)",
          height: "8%",
          background: votecolor ? getVoteColor(0) : background,
          marginBottom: botPercantage,
        }}
        key={zeroi}
      />
    );
  }
  for (let oni = 0; oni < sdgvotes["1"]; oni++) {
    const [angle, botPercantage] = getAngleAndDist(sdgvotes["1"], oni);
    PositiveBars.push(
      <div
        className={styles.bar}
        style={{
          transform: "rotate(" + angle + "deg)",
          height: "34%",
          background: votecolor ? getVoteColor(1) : background,
          marginBottom: botPercantage,
        }}
        key={oni}
      />
    );
  }
  for (let twoi = 0; twoi < sdgvotes["2"]; twoi++) {
    const [angle, botPercantage] = getAngleAndDist(sdgvotes["2"], twoi);
    PositiveBars.push(
      <div
        className={styles.bar}
        style={{
          transform: "rotate(" + angle + "deg)",
          height: "67%",
          background: votecolor ? getVoteColor(2) : background,
          marginBottom: botPercantage,
        }}
        key={twoi}
      />
    );
  }
  for (let threei = 0; threei < sdgvotes["3"]; threei++) {
    const [angle, botPercantage] = getAngleAndDist(sdgvotes["3"], threei);
    PositiveBars.push(
      <div
        className={styles.bar}
        style={{
          transform: "rotate(" + angle + "deg)",
          height: "100%",
          background: votecolor ? getVoteColor(3) : background,
          marginBottom: botPercantage,
        }}
        key={threei}
      />
    );
  }

  for (let minonei = 0; minonei < sdgvotes["-1"]; minonei++) {
    const [angle, botPercantage] = getAngleAndDist(sdgvotes["-2"], minonei);
    NegativeBars.push(
      <div
        className={styles.bar}
        style={{
          transform: "rotate(" + angle + "deg)",
          height: "34%",
          background: votecolor ? getVoteColor(-1) : background,
          marginBottom: botPercantage
        }}
        key={minonei}
      />
    );
  }
  for (let mintwoi = 0; mintwoi < sdgvotes["-2"]; mintwoi++) {
    const [angle, botPercantage] = getAngleAndDist(sdgvotes["-2"], mintwoi);
    NegativeBars.push(
      <div
        className={styles.bar}
        style={{
          transform: "rotate(" + angle + "deg)",
          height: "67%",
          background: votecolor ? getVoteColor(-2) : background,
          marginBottom: botPercantage
        }}
        key={mintwoi}
      />
    );
  }
  for (let minthreei = 0; minthreei < sdgvotes["-3"]; minthreei++) {
    const [angle, botPercantage] = getAngleAndDist(sdgvotes["-3"], minthreei);
    NegativeBars.push(
      <div
        className={styles.bar}
        style={{
          transform: "rotate(" + angle + "deg)",
          height: "100%",
          background: votecolor ? getVoteColor(-3) : background,
          marginBottom: botPercantage
        }}
        key={minthreei}
      />
    );
  }

  return (
    <div
      key={n}
      className={`${styles.wheelbars} ${styles["sdg" + n]}`}
      style={{transform: rotation}}
    >
      <div className={styles.positivebars}>{PositiveBars}</div>
      <div className={styles.negativebars}>{NegativeBars}</div>
    </div>
  );
};

export default WheelBars;
