import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

export const FacilitatorButtons = ({ strategy }) => {
	return (
		<Col className={"col-12 d-flex justify flex-column align-items-center p-4"}>
			<Row className="m-2 w-50 text-center">
				<Link to={`/relevancy_results/${strategy.id}`} className="w-100 m-auto">
					<Button className="w-100 h-100" disabled={strategy.state < 1}>1. Relevancy results</Button>
				</Link>
			</Row>
			<Row>
				<FontAwesomeIcon size={"lg"} icon={faArrowDown} />
			</Row>
			<Row className="m-2 w-50 text-center">
				<Link to={`/results/${strategy.id}/1`} className="w-100 m-auto">
					<Button className="w-100 h-100" disabled={strategy.state < 2}>2. Scoring results iteration 1</Button>
				</Link>
			</Row>
			<Row>
				<FontAwesomeIcon size={"lg"} icon={faArrowDown} />
			</Row>
			<Row className="m-2 w-50 text-center">
				<Link to={`/results/${strategy.id}/2`} className="w-100 m-auto">
					<Button className="w-100 h-100" disabled={strategy.state < 3}>3. Scoring results iteration 2</Button>
				</Link>
			</Row>
			<Row>
				<FontAwesomeIcon size={"lg"} icon={faArrowDown} />
			</Row>
			<Row className="m-2 w-50 text-center">
				<Link to={`results/${strategy.id}/3`} className="w-100 m-auto">
					<Button className="w-100 h-100" disabled={strategy.state < 4}>4. Scoring results iteration 3</Button>
				</Link>
			</Row>
			<Row>
				<FontAwesomeIcon size={"lg"} icon={faArrowDown} />
			</Row>
			<Row className="m-2 w-50 text-center">
				<Link to={`/summary/${strategy.id}/3`} className="w-100 m-auto">
					<Button className="w-100 h-100" disabled={strategy.state < 4}>5. Scoring summary</Button>
				</Link>
			</Row>
			<Row>
				<FontAwesomeIcon size={"lg"} icon={faArrowDown} />
			</Row>
			<Row className="m-2 w-50 text-center">
				<Link to={`/dashboard/${strategy.id}/3`} className="w-100 m-auto">
					<Button className="w-100 h-100" disabled={strategy.state < 5}>6. Assessment and discussion of indirect effects</Button>
				</Link>
			</Row>
			<Row>
				<FontAwesomeIcon size={"lg"} icon={faArrowDown} />
			</Row>
			<Row className="m-2 w-50 text-center">
				<Link to={`/external-policies/${strategy.id}/3`} className="w-100 m-auto">
					<Button className="w-100 h-100" disabled={strategy.state < 5}>7. Characterising different types of indirect effects</Button>
				</Link>
			</Row>
		</Col>
	);
};
