const valueToClassName = (x) => {
  switch (x) {
    case -3:
      return "minus3"
    case -2:
      return "minus2"
    case -1:
      return "minus1"
    case 0:
      return "zero"
    case 1:
      return "plus1"
    case 2:
      return "plus2"
    case 3:
      return "plus3"
    default:
      return "zero"
  }
};

const valuesToEffectTypeColor = (contextValue, strategyValue) => {
  if (contextValue > 0) {
    if (strategyValue > 0) {
      return "#5bb848"
    } else {
      return "#ffc000"
    }
  } else {
    if (strategyValue > 0) {
      return "#ff0000"
    } else {
      return "#ffe699"
    }
  }
}

const valuesToEffectTypeClass = (contextValue, strategyValue) => {
  if ((Math.abs(contextValue) < 0.01) || (Math.abs(strategyValue) < 0.01)) {
    return ""
  }

  if (contextValue > 0) {
    if (strategyValue > 0) {
      return "plusPlus"
    } else {
      return "plusMinusOrMinusPlus"
    }
  } else {
    if (strategyValue > 0) {
      return "minusPlus"
    } else {
      return "plusMinusOrMinusPlus"
    }
  }
}

export {valuesToEffectTypeClass, valuesToEffectTypeColor, valueToClassName};