import React from "react";

const ResultsNav = ({ style, graphToShow, setGraphToShow }) => {
	return (
		<div className={style.switchbtns}>
			<button className={graphToShow === 1 ? style.active : null} onClick={() => setGraphToShow(1)}>
				Scatter
			</button>
			<button className={graphToShow === 2 ? style.active : null} onClick={() => setGraphToShow(2)}>
				Bar
			</button>
			<button className={graphToShow === 3 ? style.active : null} onClick={() => setGraphToShow(3)}>
				Wheel
			</button>
			<button className={graphToShow === 4 ? style.active : null} onClick={() => setGraphToShow(4)}>
				Range average
			</button>
		</div>
	);
};

export default ResultsNav;
