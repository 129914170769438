import React from "react";
import { Badge, Form } from 'react-bootstrap';

import styles from "./votecard.module.css";
import { getSDGImage } from "../../img/images";

const Votecard = ({ goal, vote, amount, relevant, toggleRelevant, isAdmin }) => {
  // console.log(goal, relevant)
  return (
    <div className={styles.votecard}>
      <div className={styles.iconwrapper}>
        <img src={getSDGImage(parseInt(goal.substr(4)))} alt={goal} />
      </div>
      <div className={styles.votewrapper}>
        {isAdmin ? (
          <Form.Label htmlFor={goal}>
            <p>Score</p>
            <p>
              {vote} / {amount}
            </p>
          </Form.Label>
        ) : (
          <>
            <p>Score</p>
            <p>
              {vote} / {amount}
            </p>
          </>
        )}
        {isAdmin && (
          <Form.Control
            className={styles.decisionbox}
            name={goal}
            id={goal}
            checked={relevant}
            onChange={(e) => toggleRelevant(goal)}
            type="checkbox"
          />
        )}
        {!isAdmin && relevant && (
          <Badge pill variant="success">Relevant</Badge>
        )}
        {!isAdmin && !relevant && (
          <Badge pill variant="danger">Not Relevant</Badge>
        )}
      </div>
    </div>
  );
};

export default Votecard;
