import React, { useState } from "react";

import style from "./MentiResults.module.css";
import wheelstyle from "./../pages/wheelresults.module.css";

import ResultsPlot from "./menti/resultsplot";
import ResultsBar from "./wheel/resultsbar";
import ResultsLegend from "./menti/resultslegend";
import ResultsNav from "./menti/resultsnav";
import {Col, Row} from "react-bootstrap";
import {getVoteColor} from "./utils/resultsFunctions";
import WheelBars from "./wheel/wheelbars";
import SDGWheel from "./wheel/sdgwheel";

const MentiResults = ({ voteData, scatterData, filteredData, avgData, title, voteAmount }) => {
	const [graphToShow, setGraphToShow] = useState(1);

    const sdgNs = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17];
	const [votecolor, setVotecolor] = useState(true);
	const [linecolor, setLinecolor] = useState(false);
	const [currSDGTitle, setCurrSDGTitle] = useState(null);
	const [showCurrSDG, setShowCurrSDG] = useState(false);
	const [mouseCoord, setMouseCoord] = useState({x: 1, y: 1});

	return (
		<div>
			{filteredData.length > 0 ? (
				<>
					<ResultsNav style={style} graphToShow={graphToShow} setGraphToShow={setGraphToShow} />
					<ResultsLegend />
					{graphToShow === 1 && (
						<div className={style.graph}>
							<ResultsPlot data={scatterData} ticks={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]} />
						</div>
					)}
					{graphToShow === 2 && (
						<ResultsBar
							style={style}
							json={voteData}
							yLabel="Scores"
							keys={[-3, -2, -1, 0, 1, 2, 3]}
							tooltiptext="Amount"
							min={0}
							max={voteAmount}
						/>
					)}
					{graphToShow === 3 && (
						<>
							<Row className={"h-100 mt-2 ml-2 mr-2 d-flex justify-content-between"}>
      							<Col md={12} className={"d-flex flex-row justify-content-between"}>
									<div className={wheelstyle.votecolorswitchwrapper}>
									  <label className={"p-3"} htmlFor="linecolorswitch">Line Color</label>
									  <input
										id="linecolorswitch"
										name="linecolorswitch"
										type="checkbox"
										checked={linecolor}
										onChange={() => setLinecolor(!linecolor)}
									  />
									  <label className={"p-3"} htmlFor="votecolorswitch">Vote Color</label>
									  <input
										id="votecolorswitch"
										name="votecolorswitch"
										type="checkbox"
										checked={votecolor}
										onChange={() => setVotecolor(!votecolor)}
									  />
									</div>
								</Col>
							</Row>


							<Row>
							  <Col sm={12}>
								<div
								  className={wheelstyle.sdgtitlewrapper}
								  style={{
									opacity: showCurrSDG ? 1 : 0,
									top: mouseCoord.y * 1.03,
									left: mouseCoord.x * 1.03,
									pointerEvents: "none",
								  }}
								>
								  <h2>{currSDGTitle}</h2>
								</div>
							  </Col>
							  <Col sm={12} className={"d-flex justify-content-around"}>
								<>
								  <div
									className={wheelstyle.wheel}
									style={{borderColor: linecolor ? getVoteColor(3) : "#dcdcdc"}}
								  >
									<div
									  className={wheelstyle.twoline}
									  style={{borderColor: linecolor ? getVoteColor(2) : "#dcdcdc"}}
									>
									  <div
										className={wheelstyle.oneline}
										style={{borderColor: linecolor ? getVoteColor(1) : "#dcdcdc"}}
									  >
										<div className={wheelstyle.zeroline}>
										  {voteData.map((sdgvotes, n) => (
											<WheelBars n={n + 1} sdgvotes={sdgvotes} votecolor={votecolor}/>
										  ))}
										  <div
											className={wheelstyle.minusline}
											style={{borderColor: linecolor ? getVoteColor(-1) : "#dcdcdc"}}
										  >
											<div
											  className={wheelstyle.minustwoline}
											  style={{
												borderColor: linecolor ? getVoteColor(-2) : "#dcdcdc",
											  }}
											>
											  <div
												className={wheelstyle.minusthreeline}
												style={{
												  borderColor: linecolor ? getVoteColor(-3) : "#dcdcdc",
												}}
											  >
												<SDGWheel
												  averageData={avgData}
												  sdgNs={sdgNs}
												  wheelstyle={wheelstyle}
												  hover={(val, x, y) => {
													setShowCurrSDG(true);
													setCurrSDGTitle(val);
													setMouseCoord({x, y});
												  }}
												  leave={() => setShowCurrSDG(false)}
												/>
											  </div>
											</div>
										  </div>
										</div>
									  </div>
									</div>
								  </div>
								</>
							  </Col>
							</Row>
						  </>
					)}
					{graphToShow === 4 && (
						<ResultsBar
							style={style}
							json={avgData}
							yLabel="Average"
							keys={["lower_range", "upper_range"]}
							tooltiptext="Average"
							min={-3}
							max={3}
						/>
					)}
				</>
			) : (
				<h3>Could not find workshop result</h3>
			)}
		</div>
	);
};

export default MentiResults;
