import React, { useEffect, useState } from "react";
import Votecard from "../components/menti/votecard";
import { useParams } from "react-router";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { getStrategy, submitRelevancyDecisions } from "../data/strategies";
import { getProcess } from "../data/processes";
import { Button, Col, Row } from "react-bootstrap";
import useInterval from "../hooks/useInterval";
import { validateSession } from "../data/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const RelevancyResults = ({ ...props }) => {
	let { id } = useParams();
	const [cookies] = useCookies(["csrftoken"]);
	const history = useHistory();

	let goalsArr = [];
	for (let i = 1; i <= 17; i++) {
		goalsArr.push({ name: "goal" + i, relevant: false });
	}

	const user = JSON.parse(localStorage.getItem("CompassUser"));
	const [submitPushed, setSubmitPushed] = useState(false);
	const [goals, setGoals] = useState(goalsArr);
	const [strategy, setStrategy] = useState({});
	const [isAdmin, setIsAdmin] = useState(false);

	const updateRelevancy = async () => {
		let strategy = await getStrategy(id);
		setStrategy(strategy);

		let newGoals = goals.slice();

		if (!isAdmin) {
			newGoals.forEach((goal) => {
				let idx = parseInt(goal.name.substr(4));
				goal.relevant = strategy.relevancy_decisions[idx - 1];
			});
			setGoals(newGoals);
		}

		return strategy;
	};

	useEffect(() => {
		const fcn = async () => {
			if (!(await validateSession())) {
				history.push("/session-expired");
				return;
			}
			let st = await updateRelevancy();
			let process = await getProcess(st.process);
			if (process.facilitators.map((x) => x.id).includes(user.pk)) {
				setIsAdmin(true);
			}
		};
		fcn();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useInterval(async () => {
		console.log("Updating...");
		await updateRelevancy();
	}, 5000);

	const toggleRelevant = async (goal) => {
		const index = goals.map((goal) => goal.name).indexOf(goal);
		const copyGoals = goals.slice();
		copyGoals[index] = { name: goal, relevant: !copyGoals[index].relevant };
		await submitRelevancyDecisions(id, copyGoals, cookies.csrftoken);
		setGoals(copyGoals);
	};

	const handleSubmit = async (e) => {
		setSubmitPushed(true);
		if (e) {
			e.preventDefault();
		}
		await submitRelevancyDecisions(id, goals, cookies.csrftoken);
		history.push(`/`);
	};

	return (
		<main className={"m-4 p-4 col-12 col-md-5 mx-auto"}>
			<h2>Relevancy results</h2>
			<Row>
				{Object.keys(strategy).length > 0 ? (
					goals
						.map((goal, i) => {
							const currVote = strategy.relevancy_votes[0][i];
							const amount = strategy.relevancy_votes[1][i];
							return (
								<Col md={12}>
									<Votecard
										isAdmin={isAdmin}
										goal={goal.name}
										vote={currVote}
										amount={amount}
										relevant={goal.relevant}
										toggleRelevant={(goal) => toggleRelevant(goal)}
										key={i}
									/>
								</Col>
							);
						})
						.sort((el1, el2) => {
							const prevVote = strategy.relevancy_votes[0][el1.props.children.key];
							const currVote = strategy.relevancy_votes[0][el2.props.children.key];
							return prevVote > currVote ? -1 : 1;
						})
				) : (
					<Col md={12} className={"text-center"}>
						<h1>Loading...</h1>
					</Col>
				)}
			</Row>
			<Row>
				<Col md={12} className={"d-flex justify-content-end"}>
					{isAdmin && (
						<Button className={"col-12"} variant={"success"} size={"lg"} type="submit" disabled={submitPushed} onClick={(e) => handleSubmit(e)}>
							{submitPushed ? <FontAwesomeIcon icon={faSpinner} spin /> : "Submit"}
						</Button>
					)}
					{!isAdmin && (
						<Button className={"col-12"}
							onClick={(e) => {
								history.push(`/scoring/${strategy.id}/1`);
							}}>
							Continue
						</Button>
					)}
				</Col>
			</Row>
		</main>
	);
};

export default RelevancyResults;
