import React, {Component} from "react";

import style from "./goal.module.css";
import {getSDGImage} from "../../img/images";

class Goal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      val: 0,
      prefix: null,
      noimpact: false,
    };

    this.increase = this.increase.bind(this);
    this.decrease = this.decrease.bind(this);
    this.setPrefix = this.setPrefix.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
  }

  increase() {
    const val = this.state.val === 3 ? 3 : this.state.val + 1;
    this.setState({val: val, noimpact: false});
    this.setPrefix(1);
    this.handleChange(val);
  }

  decrease() {
    const val = this.state.val === -3 ? -3 : this.state.val - 1;
    this.setState({val: val, noimpact: false});
    this.setPrefix(-1);
    this.handleChange(val);
  }

  setPrefix(change) {
    this.state.val + change > 0 ? this.setState({prefix: "+"}) : this.setState({prefix: "-"});
  }

  handleChange(val) {
    if (this.props.addValue) {
      const obj = {};
      obj[this.props.goalIndex] = val;
      this.props.addValue(obj);
      return;
    }
    return false;
  }

  handleCheckbox() {
    if (!this.state.noimpact) {
      this.setState({
        val: 0,
        noimpact: true,
        prefix: null,
      });
      this.handleChange(0);
      this.props.removeValue(this.props.goalIndex);
    } else {
      this.setState({
        noimpact: false,
      });
    }
  }

  componentDidMount() {
    if (this.props.voteobj) {
      if (this.props.voteobj.lower_range !== 0 || this.props.voteobj.upper_range !== 0) {
        const val =
          this.props.voteobj.lower_range < 0
            ? this.props.voteobj.lower_range
            : this.props.voteobj.upper_range;
        const prefix = this.props.voteobj.lower_range < 0 ? "-" : "+";
        this.setState({val, prefix});
        // TODO: Better solution for handleChange than timeout
        setTimeout(() => this.handleChange(val), 100);
      }
    }
  }

  render() {
    const {goal} = this.props;

    return (
      <div className={`${style.goalwrapper}`} style={!this.props.included ? {display: "none"} : {}}>
        <div className={style.imgwrapper}>
          <img src={getSDGImage(parseInt(goal.substr(4)))} alt={goal}/>
        </div>
        <div className={style.btnwrapper}>
          <button className={style.btn} onClick={this.decrease}>
            -
          </button>
          <div className={style.valuewrapper}>
            <span>{this.state.prefix}</span>
            <input
              className={style.goalinput}
              name={goal}
              value={Math.abs(this.state.val)}
              readOnly
              type="number"
              min="-3"
              max="3"
            ></input>
          </div>
          <button className={style.btn} onClick={this.increase}>
            +
          </button>
        </div>
        <label htmlFor={`no${goal}`} className={style.noimpactwrapper}>
          <span>No impact</span>
          <input
            name={`no${goal}`}
            id={`no${goal}`}
            type="checkbox"
            checked={this.state.noimpact}
            onChange={this.handleCheckbox}
          />
        </label>
      </div>
    );
  }
}

export default Goal;
