import React from "react";
import { Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

const ParticipantButtons = ({ strategy, is_facilitator }) => {
	return (
		<Col className={"col-12 d-flex flex-column justify-content-center align-items-center p-4"}>
			<Row className={"m-2"}>
				<Link to={`/relevancyvote/${strategy.id}`}>
					<Button className="w-100 h-100" disabled={strategy.state < 1}>
						1. <br />
						SDG Relevancy Assessment
					</Button>
				</Link>
			</Row>
			<Row>
				<FontAwesomeIcon size={"lg"} icon={faArrowDown} />
			</Row>
			<Row className={"m-2"}>
				<Link to={`/scoring/${strategy.id}/1`}>
					<Button className="w-100 h-100" disabled={strategy.state < 2}>
						2. <br /> Initial Scoring Assessment
					</Button>
				</Link>
			</Row>
			<Row>
				<FontAwesomeIcon size={"lg"} icon={faArrowDown} />
			</Row>
			<Row className={"m-2"}>
				<Link to={`/scoring/${strategy.id}/2`}>
					<Button className="w-100 h-100" disabled={strategy.state < 3}>
						3. <br /> Refined Scoring Assessment
					</Button>
				</Link>
			</Row>
			<Row>
				<FontAwesomeIcon size={"lg"} icon={faArrowDown} />
			</Row>
			<Row className={"m-2"}>
				<Link to={`/scoring/${strategy.id}/3`}>
					<Button className="w-100 h-100" disabled={strategy.state < 4}>
						4. <br /> Final Scoring Assessment
					</Button>
				</Link>
			</Row>
		</Col>
	);
};

export default ParticipantButtons;
