import React, { useState } from "react";
import { Navbar } from "react-bootstrap";
import { wheelLogo } from "./img/images";
import { BrowserRouter as Router, Link, Route } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import ProcessList from "./pages/ProcessList";
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./pages/Dashboard";
import ResultsSummary from "./pages/ResultsSummary";
import ExternalPolicies from "./pages/ExternalPolicies";
import CreateStrategyForm from "./pages/CreateStrategyForm";
import RelevancyVote from "./pages/RelevancyVote";
import RelevancyResults from "./pages/RelevancyResults";
import AddPersonToProcess from './pages/AddPersonToStrategy'
import ScoringResults from "./pages/ScoringResults";
import SessionExpired from "./pages/SessionExpired";
import Scoring from "./pages/Scoring";

function App() {

  const [user, setUser] = useState(localStorage.getItem("CompassUser") !== null ? JSON.parse(localStorage.getItem("CompassUser")) : {})

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <Navbar bg="light" variant="light">
            <Navbar.Brand>
              <Link to={"/"}>
                <img
                  alt=""
                  src={wheelLogo}
                  width="30"
                  height="30"
                  className="d-inline-block align-top"
                />{' '}
                <span className="brand-text">Agenda 2030 Compass - Process Tool</span>
              </Link>
            </Navbar.Brand>
            <Navbar.Collapse className="justify-content-end">
              {
                Object.keys(user).length > 0 &&
                <>
                  <Navbar.Text>
                    <div>
                      Signed in
                      as: {user.first_name.length > 0 ? `${user.first_name} ${user.last_name}` : user.username}&nbsp;

                    </div>

                  </Navbar.Text>
                  <Navbar.Text className={"pl-2"}>
                    <Link to={"/logout"}><FontAwesomeIcon icon={faSignOutAlt} /></Link>
                  </Navbar.Text>
                </>
              }
            </Navbar.Collapse>
          </Navbar>
        </header>
        {/* Home page */}
        <ProtectedRoute exact path="/" component={ProcessList} user={user} />
        <Route path="/login">
          <Login setUser={setUser} />
        </Route>
        <Route path="/logout">
          <Logout setUser={setUser} />
        </Route>
        <Route path="/session-expired">
          <SessionExpired setUser={setUser} />
        </Route>
        <ProtectedRoute path="/dashboard/:strategyId/:scoringIteration">
          <Dashboard />
        </ProtectedRoute>
        <ProtectedRoute path="/summary/:strategyId/:scoringIteration">
          <ResultsSummary />
        </ProtectedRoute>
        <ProtectedRoute path="/create-strategy/:id">
          <CreateStrategyForm />
        </ProtectedRoute>
        <ProtectedRoute path="/external-policies/:strategyId/:scoringIteration">
          <ExternalPolicies user={user} />
        </ProtectedRoute>
        <ProtectedRoute path="/relevancyvote/:id">
          <RelevancyVote user={user} />
        </ProtectedRoute>
        <ProtectedRoute path="/relevancy_results/:id">
          <RelevancyResults user={user} />
        </ProtectedRoute>
        <ProtectedRoute path="/scoring/:strategyId/:scoringIteration">
          <Scoring user={user} />
        </ProtectedRoute>
        <ProtectedRoute path="/results/:strategyId/:scoringIteration">
          <ScoringResults user={user} />
        </ProtectedRoute>
        <ProtectedRoute path="/add/:userType/:processId">
          <AddPersonToProcess user={user} />
        </ProtectedRoute>
      </div>
    </Router>
  );
}

export default App;
