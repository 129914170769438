import './ContextAndStrategyForm.css'

import React, { useEffect, useState } from 'react';

import { Form, Card } from 'react-bootstrap';
import { getAllContexts } from '../data/contexts';
import { validateSession } from "../data/auth";
import { useHistory } from "react-router";


const ContextAndStrategyForm = ({ contextId, setContextId, loadNewData, }) => {
  const history = useHistory();

  const [contexts, setContexts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const prepareData = async () => {
      if (!(await validateSession())) {
        history.push('/session-expired')
        return;
      }
      let data = await getAllContexts()
      setContexts(data.map((value) => {
        return { id: value.id, title: value.name }
      }))
      loadNewData(contextId);
    }
    if (loading) {
      prepareData()
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleContextChange(event) {
    setContextId(event.target.value);
    loadNewData(event.target.value);
  }
  // style={{ position: "fixed", top: 0, left: "40%" }}

  return (
    <Form>
      <Card className="w-100 pb-2 border-0">
        <Card.Body>
          <Form.Row>
            <Form.Label htmlFor="contextselect" style={{ fontWeight: "bold" }}>Select Context Matrix</Form.Label>
            <Form.Control as="select" name="contextselect" value={contextId} onChange={handleContextChange}>
              {contexts.map((e, key) => {
                return <option key={key} value={e.id}>{e.title}</option>;
              })}
            </Form.Control>
          </Form.Row>
        </Card.Body>
      </Card>
    </Form>
  );
}

export default ContextAndStrategyForm;