import React from "react";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import {useCookies} from 'react-cookie';
import {getUser, login} from '../data/auth';
import {useHistory} from "react-router-dom";

const Login = ({setUser}) => {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [message, setMessage] = React.useState("");

  const [cookies] = useCookies(['csrftoken']);
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    let x = await login(email, password, cookies.csrftoken);
    if (x === 200) {
      let user = await getUser();
      setUser(user);
      localStorage.setItem("CompassUser", JSON.stringify(user));
      setTimeout(() => {
        history.push("/");
      }, 100);
    } else {
      setMessage("Incorrect credentials.")
    }
  }

  return (
    <main className={"p-4 h-100"}>
      <Row className={"h-100 d-flex justify-content-around"}>
        <Col className={"col-md-6"}>
          <Card className={"p-4 mb-4"}>
            <Card.Title className={"card-description-header text-center"}>Login</Card.Title>
            <Card.Body>
              <Form>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" onChange={(e) => {
                    setEmail(e.target.value)
                  }} value={email}/>
                  {/*<Form.Text className="text-muted">*/}
                  {/*  We'll never share your email with anyone else.*/}
                  {/*</Form.Text>*/}
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder="Password" onChange={(e) => {
                    setPassword(e.target.value)
                  }} value={password}/>
                </Form.Group>
                {/*<Form.Group controlId="formBasicCheckbox">*/}
                {/*  <Form.Check type="checkbox" label="Remember me"/>*/}
                {/*</Form.Group>*/}
                <div style={{margin: "1em", color: "red"}}>{message}</div>
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </main>
  );
}

export default Login;
