import {useParams} from "react-router";
import {Button, Card, Col, Form, Row} from "react-bootstrap";
import React, {useEffect} from "react";
import {createNewStrategy} from '../data/strategies';
import {useCookies} from "react-cookie";
import {useHistory} from "react-router-dom";
import {validateSession} from "../data/auth";


function CreateStrategyForm() {
  let {id} = useParams();
  const [cookies] = useCookies(['csrftoken']);
  const history = useHistory();

  const [name, setName] = React.useState("");
  const [description, setDescription] = React.useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    await createNewStrategy(id, name, description, cookies.csrftoken)
    history.push('/')
  }

  useEffect(() => {
    validateSession().then((isAuthenticated) => {
      if (!isAuthenticated) {
        history.push('/session-expired')
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <main className={"p-4 h-100"}>
      <Row className={"h-100 d-flex justify-content-around"}>
        <Col className={"col-md-6"}>
          <Card className={"p-4 mb-4"}>
            <Card.Title className={"card-description-header text-center"}>Create a Strategy</Card.Title>
            <Card.Body>
              <Form>
                <Form.Group controlId="formBasicEmail">
                  <Form.Label>Strategy name</Form.Label>
                  <Form.Control type="text" placeholder="Enter strategy name" onChange={(e) => {
                    setName(e.target.value)
                  }} value={name}/>
                </Form.Group>

                <Form.Group controlId="formBasicPassword">
                  <Form.Label>Description</Form.Label>
                  <Form.Control as="textarea" rows={3} placeholder="Enter description" onChange={(e) => {
                    setDescription(e.target.value)
                  }} value={description}/>
                </Form.Group>
                <Button variant="success" type="submit" onClick={handleSubmit}>
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
          <Button
            className="btn-lg"
            variant={"primary"}
            onClick={() => history.push(`/?p=${id}`)}
          >
            Back
          </Button>
        </Col>
      </Row>
    </main>
  )
}

export default CreateStrategyForm;