import React from "react";
import {Form} from 'react-bootstrap';

import styles from "./relevantcard.module.css";
import {getSDGImage} from "../../img/images";

const RelevantCard = ({goal, value, handleChange}) => {
  console.log(value);
  return (
    <div className={styles.wrapper}>
      <div className={styles.imgwrapper}>
        <img src={getSDGImage(parseInt(goal.substr(4)))} alt={goal}/>
      </div>
      <div className={styles.votewrapper}>
        <Form.Label htmlFor={goal}>Relevant</Form.Label>
        <Form.Control name={goal} id={goal} checked={value} type="checkbox" className={styles.checkbox}
                      onChange={() => handleChange(goal)}/>
      </div>
    </div>
  );
};

export default RelevantCard;
