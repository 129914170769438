import React, {useEffect, useState} from "react";

import wheelstyle from "./wheelresults.module.css";

import {useParams} from "react-router";
import {getStrategy, getVotesForStrategyAndIteration} from "../data/strategies";
import {Button, Col, Row} from "react-bootstrap";
import {useHistory} from "react-router-dom";
import useInterval from "../hooks/useInterval";
import DirectEffectsPlot from "../components/DirectEffectsPlot";
import {calculateStrategyAverageFromVotes} from "../data/calculate";
import {validateSession} from "../data/auth";

const ResultsSummary = ({user, ...props}) => {
  let {strategyId} = useParams();
  const history = useHistory();

  const [strategyArraySecond, setStrategyArraySecond] = useState([])
  const [strategyArrayThird, setStrategyArrayThird] = useState([])

  const [title, setTitle] = useState("No title yet");
  const [loading, setLoading] = useState("true");
  const [hasRequestedData, setHasRequestedData] = useState("false");

  const [strategy, setStrategy] = useState({})

  const updateVotes = async () => {
    let currentVotesSecond = await getVotesForStrategyAndIteration(strategyId, 2);
    let currentVotesThird = await getVotesForStrategyAndIteration(strategyId, 3);

    let thisStrategy = calculateStrategyAverageFromVotes(currentVotesSecond);
    setStrategyArraySecond(thisStrategy);

    thisStrategy = calculateStrategyAverageFromVotes(currentVotesThird);
    setStrategyArrayThird(thisStrategy);

    return strategy;
  }

  useEffect(() => {
    const fcn = async () => {
      if (!(await validateSession())) {
        history.push('/session-expired')
        return;
      }
      let strategy = await getStrategy(strategyId);
      setStrategy(strategy);
      setTitle(strategy.name);
      await updateVotes();
      setLoading(false);
    }
    if (hasRequestedData) {
      setHasRequestedData(true);
      fcn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useInterval(async () => {
    await updateVotes()
  }, 5000)

  return (
    <Row className={"h-100 mt-2 ml-2 mr-2 d-flex justify-content-between"}>
      <Col md={12} className={"d-flex flex-row justify-content-between"}>
        <div className={wheelstyle.info}>
          {title && <h3 className={"pb-4"}>Strategy: {title}</h3>}
        </div>

      </Col>
      {loading ? (
        <Col sm={12} className={""}>
          <h3 className={"text-center"}>Loading...</h3>
        </Col>
      ) : (
        <Col sm={12} className={""}>
          <Row>
            <Col sm={6} className={"d-flex justify-content-around w-100 flex-column"}>
              <h3 className="text-center">Direct effect on the SDGs by Strategy assessment, iteration 2:</h3>
              <DirectEffectsPlot strategy={strategyArraySecond} iteration={1} fullscreen={true} showIcons={true}/>
            </Col>
            <Col sm={6} className={"d-flex justify-content-around w-100 flex-column"}>
              <h3 className="text-center">Direct effect on the SDGs by Strategy assessment, iteration 3:</h3>
              <DirectEffectsPlot strategy={strategyArrayThird} iteration={2} fullscreen={true} showIcons={true}/>
            </Col>
          </Row>
        </Col>
      )}
      <Col sm={12} className={"mb-2"}>
        <Button variant={"primary"} size={"lg"} onClick={() => {
          history.push(`/?p=${strategy.process}&s=${strategy.id}`)
        }}>Continue</Button>
      </Col>
    </Row>
  );
};

export default ResultsSummary;
