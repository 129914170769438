export const createNewStrategy = async (processId, name, description, csrftoken) => {
  const myHeaders = new Headers();
  myHeaders.append("X-CSRFToken", csrftoken);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({"name": name, "description": description, "process": processId});

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
    credentials: 'include',
    mode: 'cors',
  };

  const response = await fetch("/api/strategies/", requestOptions)
  return await response.json()
}

export const createNewComment = async (strategyId, user_id, sdgIndices, commentText, iteration, csrftoken) => {
  const myHeaders = new Headers();
  myHeaders.append("X-CSRFToken", csrftoken);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify(
    {
      "strategy": strategyId,
      "sdg_1": sdgIndices[0],
      "sdg_2": sdgIndices[1],
      "user": user_id,
      "iteration": iteration,
      "comment": commentText
    }
  );

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
    credentials: 'include',
    mode: 'cors',
  };

  const response = await fetch("/api/comments/", requestOptions)
  return await response.json()
}

export const getCommentsForStrategyAndIteration = async (strategyId, iteration) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
    credentials: 'include',
    mode: 'cors',
  };

  const response = await fetch(`/api/comments/?strategy=${strategyId}&iteration=${iteration}`, requestOptions)
  return await response.json()
}


export const getStrategy = async (strategyId) => {

  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
    credentials: 'include',
    mode: 'cors',
  };

  const response = await fetch(`/api/strategies/${strategyId}/`, requestOptions)
  return await response.json()
}

export const changeStrategyState = async (strategyId, change, csrftoken) => {
  const myHeaders = new Headers();
  myHeaders.append("X-CSRFToken", csrftoken);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: JSON.stringify({"change": change}),
    redirect: 'follow',
    credentials: 'include',
    mode: 'cors',
  };

  const response = await fetch(`/api/strategy/${strategyId}/`, requestOptions)
  return await response.json()
}


export const changeUseWheelState = async (strategyId, change, csrftoken) => {
  const myHeaders = new Headers();
  myHeaders.append("X-CSRFToken", csrftoken);
  myHeaders.append("Content-Type", "application/json");

  const requestOptions = {
    method: 'PUT',
    headers: myHeaders,
    body: JSON.stringify({"use_wheel": change}),
    redirect: 'follow',
    credentials: 'include',
    mode: 'cors',
  };

  const response = await fetch(`/api/strategy/${strategyId}/`, requestOptions)
  return await response.json()
}


export const getRelevancyVotesForUserAndStrategy = async (userId, strategyId) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
    credentials: 'include',
    mode: 'cors',
  };

  const response = await fetch(`/api/relevancy_vote/?strategy=${strategyId}&user=${userId}`, requestOptions)
  return await response.json()
}


export const submitRelevancyVotes = async (strategyId, votes, csrftoken) => {
  const myHeaders = new Headers();
  myHeaders.append("X-CSRFToken", csrftoken);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({"strategy_id": strategyId, "votes": votes});

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
    credentials: 'include',
    mode: 'cors',
  };

  const response = await fetch(`/api/register_relevancy_votes/`, requestOptions)
  return response.ok;
  //return await response.json()
};

export const submitRelevancyDecisions = async (strategyId, decisions, csrftoken) => {
  const myHeaders = new Headers();
  myHeaders.append("X-CSRFToken", csrftoken);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({"strategy_id": strategyId, "decisions": decisions});

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
    credentials: 'include',
    mode: 'cors',
  };

  const response = await fetch(`/api/register_relevancy_decisions/`, requestOptions)
  return response.ok;
  //return await response.json()
};


export const getVotesForUserStrategyAndIteration = async (userId, strategyId, iteration) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
    credentials: 'include',
    mode: 'cors',
  };

  const response = await fetch(`/api/vote/?strategy=${strategyId}&user=${userId}&iteration=${iteration}`, requestOptions)
  return await response.json()
}


export const getVotesForStrategyAndIteration = async (strategyId, iteration) => {
  const requestOptions = {
    method: 'GET',
    redirect: 'follow',
    credentials: 'include',
    mode: 'cors',
  };

  const response = await fetch(`/api/get_votes/${strategyId}/${iteration}`, requestOptions)
  return await response.json()
}


export const submitVotes = async (strategyId, votes, csrftoken) => {
  const myHeaders = new Headers();
  myHeaders.append("X-CSRFToken", csrftoken);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({"strategy_id": strategyId, "votes": votes});

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
    credentials: 'include',
    mode: 'cors',
  };

  const response = await fetch(`/api/register_votes/`, requestOptions)
  return response.ok;
  //return await response.json()
};

export const addFacilitator = async (processId, userId, csrftoken) => {
  const myHeaders = new Headers();
  myHeaders.append("X-CSRFToken", csrftoken);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({"process_id": processId, "user_id": userId});

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
    credentials: 'include',
    mode: 'cors',
  };

  const response = await fetch(`/api/register_facilitator/`, requestOptions)
  return response.ok;
}

export const addParticipant = async (processId, userId, csrftoken) => {
  const myHeaders = new Headers();
  myHeaders.append("X-CSRFToken", csrftoken);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({"process_id": processId, "user_id": userId});

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: raw,
    redirect: 'follow',
    credentials: 'include',
    mode: 'cors',
  };

  const response = await fetch(`/api/register_participant/`, requestOptions)
  return response.ok;
}