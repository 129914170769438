import React, { Component } from "react";

import { ResponsiveBar } from "@nivo/bar";

class ResultsBar extends Component {
	render() {
		const { json, style, keys, yLabel, tooltiptext, min, max } = this.props;
		let bottomTicks = [];
		for (let i = min; i <= max; i++) {
		  bottomTicks.push(i);
		}
		return (
			<div className={style.bar}>
				<ResponsiveBar
					data={json}
					keys={keys}
					indexBy="title"
					layout="horizontal"
					margin={{ top: 50, right: 30, bottom: 50, left: 60 }}
					labelSkipWidth={16}
					labelSkipHeight={16}
					minValue={min}
					maxValue={max}
					axisLeft={{legend: yLabel}}
					axisBottom={{tickValues: bottomTicks}}
					colors={({ id, data }) => data[`${id}Color`]}
					tooltip={({ id, value, color }) => (
						<strong
							style={{
								color: "#fff",
								background: "#333",
								padding: "12px 16px",
							}}>
							{tooltiptext}: {value}
						</strong>
					)}
				/>
			</div>
		);
	}
}

export default ResultsBar;
