import React, { useEffect, useState } from "react";
import { Accordion, Badge, Button, Card, Col, Row } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import StrategyProcess from "../components/strategyprocess/StrategyProcess";
import { getStrategy } from "../data/strategies";
import { getProcesses } from "../data/processes";
import { validateSession } from "../data/auth";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ProcessList({ user }) {

  const [processes, setProcesses] = useState([]);
  const [hasRequestedProcesses, setHasRequestedProcesses] = useState(false);

  const history = useHistory();

  // Remembering which Accordion tabs were open.
  let query = useQuery();
  let p = "-1";
  if (query.get("p")) {
    p = "\"p" + query.get("p") + "\""
  } else if (localStorage.getItem('p')) {
    p = localStorage.getItem('p');
  }

  let s = "-1";
  if (query.get("s")) {
    s = "\"s" + query.get("s") + "\""
  } else if (localStorage.getItem('s')) {
    s = localStorage.getItem('s');
  }

  useEffect(() => {
    async function fetchData() {
      if (!(await validateSession())) {
        history.push('/session-expired')
        return;
      }
      const procs = await getProcesses();
      procs.forEach(p => {
        p.isFacilitator = p.facilitators.map(x => x.id).includes(user.pk);
      })
      if (!hasRequestedProcesses) {
        setHasRequestedProcesses(true);
        setProcesses(procs);
      }
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateStrategy = (strategy) => {
    let procs = processes.slice();
    for (let i = 0; i < procs.length; i++) {
      for (let j = 0; j < procs[i].strategies.length; j++) {
        if (procs[i].strategies[j].id === strategy.id) {
          procs[i].strategies[j] = strategy;
          setProcesses(procs);
        }
      }
    }
  }

  const reloadStrategy = async (strategyId) => {
    let procs = processes.slice();
    for (let i = 0; i < procs.length; i++) {
      for (let j = 0; j < procs[i].strategies.length; j++) {
        if (procs[i].strategies[j].id === strategyId) {
          procs[i].strategies[j] = await getStrategy(strategyId);
          setProcesses(procs);
        }
      }
    }
  }

  return (
    <main className={"m-4"}>
      <Row className={"d-flex justify-content-center"}>
        <Col md="10">
          <Card className={"p-4 m-auto mb-4"} style={{ maxWidth: "1200px" }}>
            <Card.Title className={"card-description-header"}>Processes</Card.Title>
            <Accordion defaultActiveKey={p}>
              {
                processes.map((item, i) => {
                  return (
                    <Card key={i}>
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey={"\"p" + item.id + "\""}
                        className={"d-flex justify-content-between align-items-end"}
                        onClick={() => {
                          localStorage.setItem('p', "\"p" + item.id + "\"")
                        }}
                      >
                        <div className={"d-flex align-items-center"}>
                          <h4 style={{ marginBottom: 0, paddingRight: "5px" }}>{item.name}</h4>
                          {
                            item.isFacilitator ?
                              <Badge pill variant="success">Facilitator</Badge> :
                              <Badge pill variant="primary">Participant</Badge>
                          }
                        </div>
                        <p style={{
                          marginBottom: 0,
                          color: "#525252"
                        }}>{new Date(item.created).toLocaleString("sv-SE")}</p>

                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={"\"p" + item.id + "\""}>
                        <Card.Body>
                          <Row>
                            <Col className={"col-12 pb-4"}>
                              <Card.Subtitle style={{
                                marginBottom: 0,
                                color: "#525252"
                              }}><strong>Description: </strong>{item.description}</Card.Subtitle>
                            </Col>
                            <Col className={"col-12"}>
                              {
                                item.isFacilitator &&
                                <div className={"d-flex flex-row align-content-start pb-4"}>
                                  <Button variant="success" size={"lg"} className={"mr-1"}
                                    onClick={() => history.push(`/add/facilitator/${item.id}`)}>+ Add a
                                    facilitator</Button>
                                  <Button variant="success" size={"lg"} className={"ml-1"}
                                    onClick={() => history.push(`/add/participant/${item.id}`)}>+ Add a
                                    participant</Button>
                                </div>
                              }
                            </Col>
                            <Col className={"col-12 pb-2"}>
                              <Card.Subtitle>Strategies</Card.Subtitle>
                            </Col>
                            <Col className={"col-12 pb-2"}>
                              <Accordion defaultActiveKey={s}>
                                {
                                  item.strategies.map((strategy, j) => {
                                    return (
                                      <Card key={j}>
                                        <Accordion.Toggle
                                          as={Card.Header}
                                          eventKey={"\"s" + strategy.id + "\""}
                                          className={"d-flex justify-content-between align-items-start"}
                                          onClick={() => {
                                            localStorage.setItem('s', "\"s" + strategy.id + "\"")
                                          }}
                                        >
                                          <div className={"d-flex align-items-left flex-column"}>
                                            <h4 style={{ paddingRight: "5px" }}>{strategy.name}</h4>
                                            <p style={{ paddingRight: "5px", marginBottom: 0 }}>{strategy.description}</p>
                                          </div>
                                          <p style={{
                                            marginBottom: 0,
                                            color: "#525252"
                                          }}>{new Date(strategy.created).toLocaleString("sv-SE")}</p>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={"\"s" + strategy.id + "\""}>
                                          <StrategyProcess key={j} strategy={strategy} updateStrategy={updateStrategy}
                                            reloadStrategy={reloadStrategy}
                                            is_facilitator={item.isFacilitator} />
                                        </Accordion.Collapse>
                                      </Card>
                                    )
                                  })
                                }
                              </Accordion>
                            </Col>
                            {item.isFacilitator &&
                              <Col className={"col-12 pt-2"}>
                                <Button variant="success" size={"lg"}
                                  onClick={() => history.push('/create-strategy/' + item.id)}>
                                  <strong>+</strong> Create a new strategy</Button>
                              </Col>
                            }
                          </Row>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  )
                })
              }
            </Accordion>
          </Card>
        </Col>
      </Row>
    </main>
  );
}

export default ProcessList;
