import React, {Component} from "react";
import styles from "./singlesdgicon.module.css";
import {getSDGImage} from "../../img/images";

class SingleSDGIcon extends Component {
  constructor(props) {
    super(props);

    this.state = {open: false};

    this.handleEnter = this.handleEnter.bind(this);
    this.handleLeave = this.handleLeave.bind(this);
    // this.navigate = this.navigate.bind(this);
  }

  handleEnter() {
    if (this.props.data) {
      this.setState({open: true});
    }
  }

  handleLeave() {
    if (this.props.data) {
      this.setState({open: false});
    }
  }

  // navigate(goal) {
  // 	if (this.props.data) {
  // 		navigate("/goalinfo", { state: { goal } });
  // 	}
  // }

  render() {
    const {goal, i, data} = this.props;
    const display = this.state.open ? "block" : "none";

    return (
      <div>
        {/*<div onClick={() => this.navigate(goal)} onMouseEnter={this.handleEnter} onMouseLeave={this.handleLeave}>*/}
        <div onMouseEnter={this.handleEnter} onMouseLeave={this.handleLeave}>
          {data && (
            <div style={{display}} className={styles.goalinfo}>
              <img src={getSDGImage(parseInt(goal.substr(4)))} alt={goal}/>
              <h1>{data.title}</h1>
              <p>{data.description}</p>
            </div>
          )}
          <img className={styles.icon} key={i} src={getSDGImage(parseInt(goal.substr(4)))} alt={goal}/>
        </div>
      </div>
    );
  }
}

export default SingleSDGIcon;
