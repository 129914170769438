import {
	Breadcrumb,
	BreadcrumbItem,
	Button,
	Card,
	Col,
	Container,
	Form,
	ListGroup,
	ListGroupItem,
	Modal,
	Row,
	Table
} from "react-bootstrap";
import ContextAndStrategyForm from "../components/ContextAndStrategyForm";
import BlobMatrix from "../components/BlobMatrix";
import React, { useEffect, useState } from "react";
import { getContext } from "../data/contexts";
import { useHistory, useParams } from "react-router";
import { createNewComment, getCommentsForStrategyAndIteration, getStrategy, getVotesForStrategyAndIteration } from "../data/strategies";
import { calculateEffectMatrix, calculateStrategyAverageFromVotes } from "../data/calculate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { getSDGImage } from "../img/images";
import { valuesToEffectTypeClass, valueToClassName } from "../components/utils/styling";
import { useCookies } from "react-cookie";
import { validateSession } from "../data/auth";
import {Link} from "react-router-dom";

function ExternalPolicies({ user, ...props }) {
	let { strategyId, scoringIteration } = useParams();
	const [cookies] = useCookies(["csrftoken"]);

	const [contextId, setContextId] = useState("1");
	const [contextMap, setContextMap] = useState([]);
	const [minMaxIndirectEffectsMap, setMinMaxIndirectEffectsMap] = useState([0, 0]);
	const [strategy, setStrategy] = useState([]);
	const [strategyArray, setStrategyArray] = useState([]);
	const [strategyLoaded, setStrategyLoaded] = useState(false);
	const [currentlyHoveredSDG, setCurrentlyHoveredSDG] = useState([]);
	const [currentInteractionCommentIndices, setCurrentInteractionCommentIndices] = useState([]);
	const [commentText, setCommentText] = React.useState("");
	const [comments, setComments] = useState([]);
	const [currFilter, setCurrFilter] = useState(['-+', '--']);
	const [sliderFilterPlusPlus, setSliderFilterPlusPlus] = useState(100);
	const [sliderFilterPlusMinus, setSliderFilterPlusMinus] = useState(100);
	const [sliderFilterMinusPlus, setSliderFilterMinusPlus] = useState(100);
	const [sliderFilterMinusMinus, setSliderFilterMinusMinus] = useState(100);
	// eslint-disable-next-line
	const [contextValues, setContextValues] = useState(false);

	const history = useHistory();

	const [show, setShow] = useState(false);
	const handleClose = () => {
		setCurrentInteractionCommentIndices([]);
		setShow(false);
	};
	const handleShow = (i, j) => {
		setCurrentInteractionCommentIndices([i, j]);
		setShow(true);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		let newComment = await createNewComment(
			parseInt(strategyId),
			user.pk,
			currentInteractionCommentIndices,
			commentText,
			parseInt(scoringIteration),
			cookies.csrftoken
		);
		let newComments = comments.slice();
		newComments.unshift(newComment);
		setComments(newComments);
	};

	async function loadNewData(newContextId) {
		if (newContextId === undefined) {
			newContextId = contextId;
		}
		let localStrategyArray = [];
		if (strategyArray.length === 0) {
			try {
				let votes = await getVotesForStrategyAndIteration(
					window.location.pathname.split("/")[2],
					window.location.pathname.split("/")[3]
				);
				localStrategyArray = calculateStrategyAverageFromVotes(votes);
			} catch { }
		} else {
			localStrategyArray = strategyArray;
		}

		let contextObject = await getContext(newContextId);
		setContextMap(contextObject.matrix);
		setContextValues(
			contextObject.matrix
				.flat()
				.filter((item, i, ar) => ar.indexOf(item) === i)
				.sort((x, y) => x - y)
		);
		let indirectEffectsMatrix = calculateEffectMatrix(contextObject.matrix, localStrategyArray);
		setMinMaxIndirectEffectsMap([Math.min(...indirectEffectsMatrix.flat()), Math.max(...indirectEffectsMatrix.flat())]);
	}

	useEffect(() => {
		async function prepare() {
			if (!(await validateSession())) {
				history.push("/session-expired");
				return;
			}
			let strategy = await getStrategy(strategyId);
			setStrategy(strategy);
			await loadNewData(contextId, strategyId);
			let votes = await getVotesForStrategyAndIteration(strategyId, scoringIteration);
			let thisStrategy = calculateStrategyAverageFromVotes(votes);
			setStrategyArray(thisStrategy);
			let theseComments = await getCommentsForStrategyAndIteration(strategyId, scoringIteration);
			setComments(theseComments);
		}

		if (!strategyLoaded) {
			prepare();
			setStrategyLoaded(true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleFilterChange = (filter) => {
		const filterCopy = currFilter.slice();
		if (filter && currFilter.indexOf(filter) === -1) {
			filterCopy.push(filter);
			setCurrFilter(filterCopy);
		} else {
			const filtered = filterCopy.filter((x) => x !== filter);
			setCurrFilter(filtered);
		}
	};

	return (
		<Container fluid className={"h-100 pt-4"}>
			<h2>Acupuncture points</h2>
			<Row>
				<Breadcrumb>
            <BreadcrumbItem linkAs={Link} linkProps={{to: '/'}}>
              Home
            </BreadcrumbItem>
            <BreadcrumbItem active={true}>
              Acupuncture points for "{strategy.name}"
            </BreadcrumbItem>
          </Breadcrumb>
			</Row>
			<Row className={"h-100 pb-4"}>
				<Col sm={12} md={2} xl={3} className={"context-pane d-flex flex-column"}>
					<ContextAndStrategyForm contextId={contextId} setContextId={setContextId} loadNewData={loadNewData} />
					{currentlyHoveredSDG.length === 2 &&
						contextMap[currentlyHoveredSDG[0]][currentlyHoveredSDG[1]] &&
						strategyArray[currentlyHoveredSDG[0]] && (
							<Card>
								<Card.Body>
									<div className={"d-flex flex-row align-items-center justify-content-between"}>
										<img
											className=""
											style={{ width: "60px" }}
											src={getSDGImage(currentlyHoveredSDG[0] + 1)}
											alt={"SDG " + (currentlyHoveredSDG[0] + 1).toString()}
										/>
										<p className="m-2">
											<FontAwesomeIcon size={"lg"} icon={faArrowRight} />
										</p>
										<img
											className=""
											style={{ width: "60px" }}
											src={getSDGImage(currentlyHoveredSDG[1] + 1)}
											alt={"SDG " + (currentlyHoveredSDG[1] + 1).toString()}
										/>
									</div>

									<Table bordered className={"mt-2"}>
										<tbody>
											<tr>
												<td>Direct Effects</td>
												<td
													className={
														"text-center " +
														valueToClassName(
															parseInt(
																strategyArray[
																	currentlyHoveredSDG[0]
																].toFixed(0)
															)
														)
													}>
													{strategyArray[currentlyHoveredSDG[0]].toFixed(1)}
												</td>
											</tr>
											<tr>
												<td>Context</td>
												<td
													className={
														"text-center " +
														valueToClassName(
															contextMap[currentlyHoveredSDG[0]][
															currentlyHoveredSDG[1]
															]
														)
													}>
													{contextMap[currentlyHoveredSDG[0]][
														currentlyHoveredSDG[1]
													].toFixed(1)}
												</td>
											</tr>
											<tr>
												<td>Indirect Effects</td>
												<td
													className={
														"text-center " +
														valuesToEffectTypeClass(
															contextMap[currentlyHoveredSDG[0]][
															currentlyHoveredSDG[1]
															],
															strategyArray[currentlyHoveredSDG[0]]
														)
													}>
													{(
														contextMap[currentlyHoveredSDG[0]][
														currentlyHoveredSDG[1]
														] * strategyArray[currentlyHoveredSDG[0]]
													).toFixed(1)}
												</td>
											</tr>
										</tbody>
									</Table>
								</Card.Body>
							</Card>
						)}
				</Col>
				<Col sm={12} md={10} xl={9} className={"d-flex flex-column align-items-center pb-2"}>
					<Row className={'ml-5 mr-5'}>
						<Col xs={6}>
							<Row className={"d-flex flex-row align-items-center"}>
								<Col xs={3} className={"d-flex justify-content-end"}>
									<input
										type="checkbox"
										checked={currFilter.indexOf("++") !== -1}
										onChange={() => handleFilterChange("++")}
										name="plusplus"
										id="plusplus"
									/>
								</Col>
								<Col xs={3} className={"d-flex justify-content-start"}>
									<label htmlFor="plusplus" className={"noselect"} style={{ fontSize: "2rem" }}>
										++
									</label>
								</Col>
								<Col xs={6} className={"d-flex justify-content-start"}>
									<input
										id="blob-slider-plusplus"
										name="blob-slider-plusplus"
										type="range"
										min={0}
										max={100}
										style={{ direction: "rtl" }}
										value={sliderFilterPlusPlus}
										onChange={(e) => {
											setSliderFilterPlusPlus(e.target.value);
										}}></input>
								</Col>
							</Row>
						</Col>
						<Col xs={6}>
							<Row className={"d-flex flex-row align-items-center"}>
								<Col xs={3} className={"d-flex justify-content-end"}>
									<input
										type="checkbox"
										checked={currFilter.indexOf("--") !== -1}
										onChange={() => handleFilterChange("--")}
										name="minusminus"
										id="minusminus"
									/>
								</Col>
								<Col xs={3} className={"d-flex justify-content-start"}>
									<label htmlFor="minusminus" className={"noselect"} style={{ fontSize: "2rem" }}>
										--
									</label>
								</Col>
								<Col xs={6} className={"d-flex justify-content-start"}>
									<input
										id="blob-slider-minus-minus"
										name="blob-slider-minus-minus"
										type="range"
										min={0}
										max={100}
										style={{ direction: "rtl" }}
										value={sliderFilterMinusMinus}
										onChange={(e) => {
											setSliderFilterMinusMinus(e.target.value);
										}}></input>
								</Col>
							</Row>
						</Col>
						<Col xs={6}>
							<Row className={"d-flex flex-row align-items-center"}>
								<Col xs={3} className={"d-flex justify-content-end"}>
									<input
										type="checkbox"
										checked={currFilter.indexOf("+-") !== -1}
										onChange={() => handleFilterChange("+-")}
										name="plusminus"
										id="plusminus"
									/>
								</Col>
								<Col xs={3} className={"d-flex justify-content-start"}>
									<label htmlFor="plusminus" className={"noselect"} style={{ fontSize: "2rem" }}>
										+-
									</label>
								</Col>
								<Col xs={6} className={"d-flex justify-content-start"}>
									<input
										id="blob-slider-plus-minus"
										name="blob-slider-plus-minus"
										type="range"
										min={0}
										max={100}
										style={{ direction: "rtl" }}
										value={sliderFilterPlusMinus}
										onChange={(e) => {
											setSliderFilterPlusMinus(e.target.value);
										}}></input>
								</Col>
							</Row>
						</Col>
						<Col xs={6}>
							<Row className={"d-flex flex-row align-items-center"}>
								<Col xs={3} className={"d-flex justify-content-end"}>
									<input
										type="checkbox"
										checked={currFilter.indexOf("-+") !== -1}
										onChange={() => handleFilterChange("-+")}
										name="minusplus"
										id="minusplus"
									/>
								</Col>
								<Col xs={3} className={"d-flex justify-content-start"}>
									<label htmlFor="minusplus" className={"noselect"} style={{ fontSize: "2rem" }}>
										-+
									</label>
								</Col>
								<Col xs={6} className={"d-flex justify-content-start"}>
									<input
										id="blob-slider-neg1"
										name="blob-slider-neg1"
										type="range"
										min={0}
										max={100}
										style={{ direction: "rtl" }}
										value={sliderFilterMinusPlus}
										onChange={(e) => {
											setSliderFilterMinusPlus(e.target.value);
										}}></input>
								</Col>
							</Row>
						</Col>
					</Row>

					<BlobMatrix
						contextMap={contextMap}
						strategy={strategyArray}
						setCurrentlyHoveredSDG={setCurrentlyHoveredSDG}
						comments={comments}
						clickOnInteraction={handleShow}
						filter={currFilter}
						sliderFilterPlusPlus={((100 - sliderFilterPlusPlus) * minMaxIndirectEffectsMap[1]) / 100}
						sliderFilterMinusMinus={((100 - sliderFilterMinusMinus) * minMaxIndirectEffectsMap[1]) / 100}
						sliderFilterMinusPlus={((100 - sliderFilterMinusPlus) * minMaxIndirectEffectsMap[0]) / 100}
						sliderFilterPlusMinus={((100 - sliderFilterPlusMinus) * minMaxIndirectEffectsMap[0]) / 100}
					/>
				</Col>
			</Row>

			<Modal show={show} onHide={handleClose} backdrop="static" keyboard={false} size={"xl"}>
				<Modal.Header closeButton>
					<Modal.Title>Comment on Interaction</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					<h4>
						SDG {currentInteractionCommentIndices[0]} &rarr; SDG {currentInteractionCommentIndices[1]}
					</h4>
					<Form className={"d-flex flex-column align-content-end"}>
						<Form.Group>
							<Form.Label htmlFor={"commentText"}>Add a new comment</Form.Label>
							<Form.Control
								id={"commentText"}
								as="textarea"
								rows={3}
								placeholder="Write your comment..."
								onChange={(e) => {
									setCommentText(e.target.value);
								}}
								value={commentText}
							/>
						</Form.Group>
						<Button className={""} type={"submit"} variant="success" onClick={handleSubmit}>
							Submit
						</Button>
					</Form>
					<h4 className={"mt-2"}>Existing comments</h4>
					<ListGroup variant="">
						{comments
							.filter(
								(x) =>
									x.sdg_1 === currentInteractionCommentIndices[0] &&
									x.sdg_2 === currentInteractionCommentIndices[1]
							)
							.map((comment, i) => {
								return (
									<ListGroupItem key={i}>
										<div className={"d-flex flex-row justify-content-between"}>
											<p className={"text-bold"}>Author: {comment.username}</p>
											<p className={"text-muted"}>
												{new Date(comment.created).toLocaleString("se-SV")}
											</p>
										</div>
										<p
											className={"p-2 border"}
											style={{
												whiteSpace: "pre-line",
												background: "#fafafa",
												borderRadius: "0.25rem",
											}}>
											{comment.comment}
										</p>
									</ListGroupItem>
								);
							})}
					</ListGroup>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Close
					</Button>
				</Modal.Footer>
			</Modal>
		</Container>
	);
}

export default ExternalPolicies;

// <OverlayTrigger
//   placement="auto"
//   delay={{show: 150, hide: 200}}
//   overlay={
//     <Popover id={`matrix-${index}-${index2}`} className={"text-center"}>
//       <Popover.Title className={"d-flex flex-row align-items-center"}
//                      style={{fontSize: "2rem"}}>
//         <img className=""
//              src={getSDGImage(index + 1)}
//              alt={"SDG " + (index + 1).toString()}/>
//         <p><strong>&rarr;</strong></p>
//         <img className=""
//              src={getSDGImage(index2 + 1)}
//              alt={"SDG " + (index2 + 1).toString()}/>
//       </Popover.Title>
//       <p className={"pt-2"}>Context</p>
//       <Popover.Content className={valueToClassName(cell)}
//                        style={{
//                          margin: "1rem",
//                          fontSize: "2rem",
//                          textAlign: "center"
//                        }}>{cell.toFixed(1)}</Popover.Content>
//       <p>Direct Effects</p>
//       <Popover.Content
//         className={valueToClassName(parseInt(thisStrategy[index].toFixed(0)))}
//         style={{
//           margin: "1rem",
//           fontSize: "2rem",
//           textAlign: "center"
//         }}>{thisStrategy[index].toFixed(1)}</Popover.Content>
//       <p>Indirect Effects</p>
//       <Popover.Content
//         className={valuesToEffectTypeClass(cell, thisStrategy[index])}
//         style={{
//           margin: "1rem",
//           fontSize: "2rem",
//           textAlign: "center"
//         }}>{(cell * thisStrategy[index]).toFixed(1)}</Popover.Content>
//     </Popover>
//   }
//   key={index2}
// >
