import React from "react";
import {getSDGColor} from "../utils/resultsFunctions";
import {getSDGImage} from "../../img/images";

const SDGTitles = [
  {"title": "No Poverty"},
  {"title": "Zero Hunger"},
  {"title": "Good Health and Well-being"},
  {"title": "Quality Education"},
  {"title": "Gender Equality"},
  {"title": "Clean Water and Sanitation"},
  {"title": "Affordable and Clean Energy"},
  {"title": "Decent Work and Economic Growth"},
  {"title": "Industry, Innovation and Infrastructure"},
  {"title": "Reduced Inequality"},
  {"title": "Sustainable Cities and Communities"},
  {"title": "Responsible Consumption and Production"},
  {"title": "Climate Action"},
  {"title": "Life Below Water"},
  {"title": "Life on Land"},
  {"title": "Peace and Justice Strong Institutions"},
  {"title": "Partnerships to achieve the Goal"}
]

const WheelSDG = ({wheelstyle, n, minus, average, hover, leave}) => {
  const background = getSDGColor(n);
  const rotation = minus ? "rotate(-" + minus + "deg)" : "rotate(" + 21 * n + "deg)";

  return (
    <div
      className={`${wheelstyle.sdgwrap} ${wheelstyle["sdg" + n]}`}
      style={{
        zIndex: 18 - n,
        transform: rotation,
        background: background,
        pointerEvents: "none",
        borderRight: n === 17 ? "none" : "10px solid #fff",
      }}
    >
      <div
        className={`${wheelstyle.sdgcontent} ${wheelstyle["sdg" + n + "content"]}`}
        style={{transform: "rotate(-12deg)", position: "absolute", pointerEvents: "initial"}}
        onMouseEnter={(e) => hover(`${n}: ${SDGTitles[n - 1].title}`, e.pageX, e.pageY)}
        onMouseLeave={() => leave()}
      >
        <img
          style={{transform: "rotate(-" + (21 * n - 10) + "deg)"}}
          src={getSDGImage(n)}
          className={wheelstyle.icon}
          alt={`SDG ${n} Icon`}
        />
        {average && (
          <div
            style={{transform: "rotate(-" + (21 * n - 10) + "deg)"}}
            className={wheelstyle.averagecircle}
          >
            {average}
          </div>
        )}
      </div>
    </div>
  );
};

export default WheelSDG;
