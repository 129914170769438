import goal1 from './goal1.png';
import goal2 from './goal2.png';
import goal3 from './goal3.png';
import goal4 from './goal4.png';
import goal5 from './goal5.png';
import goal6 from './goal6.png';
import goal7 from './goal7.png';
import goal8 from './goal8.png';
import goal9 from './goal9.png';
import goal10 from './goal10.png';
import goal11 from './goal11.png';
import goal12 from './goal12.png';
import goal13 from './goal13.png';
import goal14 from './goal14.png';
import goal15 from './goal15.png';
import goal16 from './goal16.png';
import goal17 from './goal17.png';

import sdgLogo from './E_SDG_logo_without_UN_emblem_Square_Transparent_WEB.png';
import wheelLogo from './SDG Wheel_Transparent_WEB.png'
import wheelWithSDGsLogo from './sdg-wheel_400.png'

const getSDGImage = (sdg_id) => {
  switch (sdg_id) {
    case 1:
      return goal1;
    case 2:
      return goal2;
    case 3:
      return goal3;
    case 4:
      return goal4;
    case 5:
      return goal5;
    case 6:
      return goal6;
    case 7:
      return goal7;
    case 8:
      return goal8;
    case 9:
      return goal9;
    case 10:
      return goal10;
    case 11:
      return goal11;
    case 12:
      return goal12;
    case 13:
      return goal13;
    case 14:
      return goal14;
    case 15:
      return goal15;
    case 16:
      return goal16;
    case 17:
      return goal17;
    default:
      return goal1;
  }
}

export {getSDGImage, sdgLogo, wheelLogo, wheelWithSDGsLogo};

