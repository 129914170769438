import React, { useEffect, useState } from "react";

import Goal from "../components/voting/goal";
import { useParams } from "react-router";
import { Button, Col, Row } from "react-bootstrap";
import { getStrategy, getVotesForUserStrategyAndIteration, submitVotes } from "../data/strategies";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { validateSession } from "../data/auth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import ResultsLegend from "../components/menti/resultslegend";

const Scoring = ({ user, ...props }) => {
  let { strategyId, scoringIteration } = useParams();
  const iteration = parseInt(scoringIteration);
  const [cookies] = useCookies(['csrftoken']);
  const history = useHistory();

  const [submitPushed, setSubmitPushed] = useState(false);
  const [included, setIncluded] = useState([]);
  const [lastvote, setLastvote] = useState([]);
  const [hasRetrievedData, setHasRetrievedData] = useState(false);

  let title = "";
  if (iteration === 1) {
    title = "Initial Scoring";
  } else if (iteration === 2) {
    title = "Refine Scoring";
  } else if (iteration === 3) {
    title = "Final Scoring";
  } else {
    title = "Undefined Iteration";
  }

  useEffect(() => {
    async function getPriorVoting() {
      if (!(await validateSession())) {
        history.push('/session-expired')
        return;
      }
      // First, get the inclusion decisions.
      let strategy = await getStrategy(strategyId);
      let includedArr = [];
      strategy.relevancy_decisions.forEach((d, idx) => {
        if (d) {
          includedArr.push((idx + 1).toString());
        }
      })
      setIncluded(includedArr);
      // Get any prior voting in this

      let priorVotes = [];
      if (iteration === 1) {
        priorVotes = await getVotesForUserStrategyAndIteration(user.pk, strategyId, 1);
      } else if (iteration === 2) {
        priorVotes = await getVotesForUserStrategyAndIteration(user.pk, strategyId, 2);
        if (priorVotes.length === 0) {
          priorVotes = await getVotesForUserStrategyAndIteration(user.pk, strategyId, 1);
        }
      } else if (iteration === 3) {
        priorVotes = await getVotesForUserStrategyAndIteration(user.pk, strategyId, 3);
        if (priorVotes.length === 0) {
          priorVotes = await getVotesForUserStrategyAndIteration(user.pk, strategyId, 2);
        }
      }
      let lastvoteArr = [];
      priorVotes.forEach((vote) => {
        lastvoteArr.push(vote);
      })
      setLastvote(lastvoteArr);
      setHasRetrievedData(true);
    }

    if (!hasRetrievedData) {
      getPriorVoting();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const emptyVotes = {
    0: 0,
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0,
    13: 0,
    14: 0,
    15: 0,
    16: 0,
  };

  const [votes, setVotes] = useState(emptyVotes);
  const goals = [];

  for (let i = 1; i <= 17; i++) {
    goals.push({ name: "goal" + i, val: lastvote && lastvote[i - 1] ? lastvote[i - 1].val : 0 });
  }

  async function handleSendSubmit() {
    setSubmitPushed(true);
    const votesArr = [];
    if (Object.keys(votes).length > 0) {
      for (const [key, value] of Object.entries(votes)) {
        const lower_range = value < 0 ? value : 0;
        const upper_range = value > 0 ? value : 0;
        votesArr.push({
          lower_range: lower_range,
          upper_range: upper_range,
          sdg: parseInt(key) + 1,
          iteration: iteration ? iteration : 1,
        });
      }
      await submitVotes(strategyId, votesArr, cookies.csrftoken);
      history.push(`/results/${strategyId}/${scoringIteration}`)
    }
  }

  function addValue(obj) {
    const key = Object.keys(obj)[0];
    setVotes({ ...votes, [key]: obj[key] });
  }

  function removeValue(goalIndex) {
    const voteCopy = Object.assign(votes);
    delete voteCopy[goalIndex];
    setVotes(voteCopy);
  }

  return (
    <main className={"m-4"}>
      <Row className={"d-flex justify-content-around pb-4"}>
        <Col className={"col-md-4 pt-4 d-flex flex-column justify-content-around"}
          style={{ border: "1px solid #dadada" }}>
          <h2 className={"text-center pb-2"}>{title}</h2>
          <ResultsLegend />
          {hasRetrievedData ? goals.map((goal, n) => {
            return (
              <Goal
                key={n}
                goal={goal.name}
                goalIndex={n}
                addValue={addValue}
                removeValue={removeValue}
                included={included.length > 0 ? included.includes((n + 1).toString()) : true}
                voteobj={lastvote ? lastvote[n] : false}
              />
            );
          }) :
            <p>Loading...</p>
          }

          <Button className={"mt-2 mb-2"} variant={"success"} size={"lg"} disabled={submitPushed} onClick={() => handleSendSubmit()}>
            {submitPushed ? <FontAwesomeIcon icon={faSpinner} spin /> : "Submit"}
          </Button>
        </Col>
      </Row>
    </main>
  );
}

export default Scoring;
