import React from "react";

import WheelSDG from "./wheelsdg";

const SDGWheel = ({sdgNs, wheelstyle, averageData, hover, leave}) => {
  return (
    <div className={wheelstyle.sdgswheel}>
      {sdgNs &&
      averageData &&
      sdgNs.map((n) => {
        const currSDG = averageData.find((data) => data.title.substr(3) === n);
        const average = averageData[n - 1] && currSDG ? currSDG.avg : false;
        return n === 1 || n === 2 || n === 3 || n === 4 ? (
          <div className={wheelstyle["sdg" + n + "wrapper"]}>
            <WheelSDG
              average={average}
              wheelstyle={wheelstyle}
              n={n}
              minus={69}
              hover={hover}
              leave={leave}
            />
          </div>
        ) : (
          <WheelSDG average={average} wheelstyle={wheelstyle} n={n} hover={hover} leave={leave}/>
        );
      })}
      <div className={wheelstyle.wheelmiddle}/>
    </div>
  );
};

export default SDGWheel;
