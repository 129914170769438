import "./StrategyProcess.css";

import { Button, Col, ListGroupItem, Row } from "react-bootstrap";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen, faSyncAlt } from "@fortawesome/free-solid-svg-icons";

import { changeStrategyState } from "../../data/strategies";
import { useCookies } from "react-cookie";
import ParticipantButtons from "./ParticipantButtons";
import { FacilitatorButtons } from "./FacilitatorButtons";

function StrategyProcess({ strategy, is_facilitator, updateStrategy, reloadStrategy, ...restOfProps }) {
	const [cookies] = useCookies(["csrftoken"]);

	return (
		<ListGroupItem className={"strategy-list-item"}>
			<Row className={"pb-3 overflow-auto"}>
				<Col className={"col-12 d-flex flex-xl-row flex-column justify-content-between p-3 align-items-center"}>
					<div className={"col-12 m-3 m-xl-0 d-flex justify-content-center"}>
						{is_facilitator && (
							<>
								<Button
									className={"m-1 col-sm-4 col-6"}
									disabled={strategy.state === 0}
									variant={"primary"}
									onClick={async () => {
										let newStrategy = await changeStrategyState(strategy.id, -1, cookies.csrftoken);
										updateStrategy(newStrategy);
									}}>
									<FontAwesomeIcon icon={faLock} />
									&nbsp;Lock current task
								</Button>
								<Button
									className={"m-1 ml-1 col-sm-4 col-6"}
									disabled={strategy.state > 11}
									variant={"primary"}
									onClick={async () => {
										let newStrategy = await changeStrategyState(strategy.id, 1, cookies.csrftoken);
										updateStrategy(newStrategy);
									}}>
									<FontAwesomeIcon icon={faLockOpen} />
									&nbsp;Unlock next task
								</Button>
							</>
						)}
						<Button
							className={"m-1 col-sm-2 col-6"}
							variant={"success"}
							onClick={async () => {
								reloadStrategy(strategy.id);
							}}>
							<FontAwesomeIcon icon={faSyncAlt} />
							&nbsp;Reload
						</Button>
					</div>
				</Col>

				{is_facilitator && <FacilitatorButtons strategy={strategy} />}

				{!is_facilitator && <ParticipantButtons strategy={strategy} is_facilitator={is_facilitator} />}
			</Row>
		</ListGroupItem>
	);
}

export default StrategyProcess;
