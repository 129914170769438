import { useParams } from "react-router";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { addFacilitator, addParticipant } from '../data/strategies';
import { useCookies } from "react-cookie";
import { useHistory } from "react-router-dom";
import { getAllUsers, validateSession } from "../data/auth";


const AddPersonToProcess = () => {
  let { userType, processId } = useParams();
  const [cookies] = useCookies(['csrftoken']);
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [loadedUsers, setLoadedUsers] = useState(false);
  const [selectedUser, setSelectedUser] = useState("1");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userType === "facilitator") {
      await addFacilitator(processId, selectedUser, cookies.csrftoken);
    } else {
      await addParticipant(processId, selectedUser, cookies.csrftoken);
    }
    history.push('/')
  }


  useEffect(() => {

    async function initiate() {
      if (!(await validateSession())) {
        history.push('/session-expired')
        return;
      }
      let newUsers = await getAllUsers();
      setUsers(newUsers);
    }

    if (!loadedUsers) {
      setLoadedUsers(true);
      initiate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleUserChange = (e) => {
    setSelectedUser(e.target.value);
    console.log(e.target.value)
  }

  return (
    <main className={"p-4 h-100"}>
      <Row className={"h-100 d-flex justify-content-around"}>
        <Col className={"col-md-6"}>
          <Card className={"p-4 mb-4"}>
            <Card.Title className={"card-description-header text-center"}>{`Add a ${userType}`}</Card.Title>
            <Card.Body>
              <Form>
                <Form.Group controlId="formAddUser">
                  <Form.Label>Add User</Form.Label>
                  <Form.Control as="select" name="contextselect" value={selectedUser} onChange={handleUserChange}>
                    {users.length > 0 && users.map((e, key) => {
                      return <option key={key} value={e.id}>{e.email.length > 0 ? e.email : e.username}</option>;
                    })}
                  </Form.Control>
                </Form.Group>
                <Button variant="success" type="submit" onClick={handleSubmit}>
                  Add
                </Button>
              </Form>
            </Card.Body>
          </Card>
          <Button
            className="btn-lg"
            variant={"primary"}
            onClick={() => history.push(`/?p=${processId}`)}
          >
            Back
          </Button>
        </Col>
      </Row>
    </main>
  )
}


export default AddPersonToProcess;
