import React from "react";

import styles from "./delta.module.css";
import SingleSDGIcon from "./singlesdgicon";
import SDGS from "../../data/sdgs";

const Delta = ({ contextMatrix, calculated, showIcons }) => {

  const cm = contextMatrix.length > 0 ? contextMatrix : [];
  let positives = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  let negatives = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  if (!calculated) {
    cm.forEach((row, row_idx) => {
      row.forEach((value, col_idx) => {
        if (value > 0) {
          positives[row_idx] += value
        } else {
          negatives[row_idx] += value
        }
      })
    });
  } else {
    positives = calculated.map(x => x > 0 ? x : 0);
    negatives = calculated.map(x => x < 0 ? x : 0);
  }

  console.log({ positives, negatives })

  const maxpos = Math.max(...positives);
  const maxneg = Math.abs(Math.min(...negatives));
  const max = maxpos > maxneg ? maxpos : maxneg;
  return (
    <div className={styles.col} >
      <div className={styles.imgcol}>
        {showIcons && negatives.map((barN, index) => {
            return (
                <div key={index} className={`${styles.row} ${styles.imgrow}`}>
                    <SingleSDGIcon
                        goal={"goal"+(index+1)}
                        data={
                          SDGS.filter(
                            obj =>
                              obj.name ===
                              "goal"+(index+1)
                          )[0]
                        }
                        i={index}
                    />
                    {/*<img*/}
                    {/*    style={{height: "33px"}}*/}
                    {/*    className=""*/}
                    {/*    src={getSDGImage(index + 1)}*/}
                    {/*    alt={"SDG " + (index + 1).toString()}*/}
                    {/*/>*/}
                </div>
            )
          })
        }
      </div>

      <div className={styles.negcol}>
        {negatives.map((barN, index) => {
          const width = `${(Math.abs(barN) / max) * 100}%`;
          return (
            <div key={index} className={`${styles.row} ${styles.negativerow}`}>
              <div className={`${styles.bar} ${styles.negative}`} style={{ width }} />
            </div>
          );
        })}
      </div>
      <div className={styles.poscol}>
        {positives.map((barN, index) => {
          const width = `${(Math.abs(barN) / max) * 100}%`;
          return (
            <div key={index} className={styles.row}>
              <div className={`${styles.bar} ${styles.positive}`} style={{ width }} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Delta;
