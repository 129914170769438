// import { getVoteColorBlind } from "./utils/resultsFunctions";
import Delta from './visuals/delta';

const DirectEffectsPlot = ({ strategy, iteration, fullscreen, showIcons }) => {
  const data_1 = strategy.length > 0 ? strategy : [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  data_1.slice().reverse();

  return (
      <Delta contextMatrix={[]} calculated={data_1} showIcons={showIcons} />
  )
}

export default DirectEffectsPlot;